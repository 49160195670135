import * as React from 'react';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Select from 'react-select';
import {NavLink} from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useState} from 'react';
import {AiOutlinePlus} from 'react-icons/ai';
import ReactHtmlParser from 'react-html-parser';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Block} from 'notiflix/build/notiflix-block-aio';
import Axios from './../../../config/api';
import moment from 'moment';
import {Editor} from 'react-draft-wysiwyg';
import {IoClose} from 'react-icons/io5';
import {
  MdOutlineModeEditOutline,
  MdOutlineComment,
  MdCheck,
  MdApproval,
  MdOutlineEdit,
  MdOutlineClose,
} from 'react-icons/md';
import {BiDotsHorizontal} from 'react-icons/bi';
import {FiChevronRight} from 'react-icons/fi';
import {TbFileDescription} from 'react-icons/tb';
import {ImAttachment} from 'react-icons/im';
import {TbSubtask} from 'react-icons/tb';
import {RiDeleteBin6Line, RiDeleteBinLine} from 'react-icons/ri';
import {GoIssueOpened} from 'react-icons/go';
import {HiOutlineDownload} from 'react-icons/hi';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SnoozeIcon from '@mui/icons-material/Snooze';
import {IoIosAttach} from 'react-icons/io';
import {FaCheck} from 'react-icons/fa6';
import {
  Avatar,
  Box,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
  ListItemIcon,
  TextField,
  Button,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import {Confirm} from 'notiflix/build/notiflix-confirm-aio';
import {useDispatch, useSelector} from 'react-redux';
import {FiEdit} from 'react-icons/fi';
import {OutlineBtn, FullBtn} from '../../common/ManagementComponents/Btn/Btn';
import {getUser} from '../../../redux/slices/userSlice';
import {DocumentsAttachments} from '../../common/ManagementComponents/DocumentsAttachments';
import {UserParticipants2} from '../../common/ManagementComponents/tasks/View';
import CommentsPdfIcon from '../../../pics/pdf.svg';
import CommentsPdfIcon2 from '../../../pics/word.svg';
import CommentsPdfIcon3 from '../../../pics/powerpoint.svg';
import CommentsPdfIcon4 from '../../../pics/excel.svg';
import CommentsPdfIcon5 from '../../../pics/file.png';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';

import {getGlobal, setGlobal} from '../../../redux/slices/globalSlice';
import {getDraftFromHtml} from './pages/Dashboard/page/CreateTask';
import draftToHtml from 'draftjs-to-html';
import {convertToRaw} from 'draft-js';
import {calDuration} from '../../../utils/functions';
import {useRef} from 'react';
import {Report} from 'notiflix';
//
import {InputBox} from '../../common/ManagementComponents/form/Form';

import Tooltip from '@mui/material/Tooltip';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
//
export default props => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const {taskId, refreshTasks} = useSelector(getGlobal);
  const [taskData, setTaskData] = useState({});
  const [delay, setDelay] = useState(null);
  const [blockingTask, setBlockingTask] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [editDesc, setEditDesc] = useState(false);
  const [newTaskDesc, setNewTaskDesc] = useState(null);
  const handleOpenTeamModal = () => setShowTeamModal(true);
  const handleCloseTeamModal = () => setShowTeamModal(false);

  useEffect(() => {
    if (taskId) fetchTaskDetail();
    return () => {};
  }, []);

  useEffect(() => {
    setDelay(moment().diff(taskData?.end_date, 'days'));
  }, [taskData]);

  const fetchTaskDetail = () => {
    //showing up loader
    Block.circle('.task_detail', 'Loading...');
    Axios.fetch(`maxproject/task/internal_task_detail/${taskId}`)
      .then(({data}) => {
        Block.remove('.task_detail');
        //getting user timer from teammembers array
        const userTimer = data?.task?.TaskMembers?.find(
          member => member.user_id == user?.id,
        );
        const TaskOwner = data?.task?.TaskMembers?.find(
          member => member.isOwner,
        );
        setTaskData({...data.task, userTime: userTimer?.TaskTimer, TaskOwner});
        setBlockingTask(data?.BlockingTask);
        setTaskList(data?.TasksList);
        setNewTaskDesc(getDraftFromHtml(data.task?.desc));
      })
      .catch(err => {
        //removing loader
        Block.remove('.task_detail');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const getTaskProgress = () => {
    if (!taskData) return 0;
    let totalIssues = taskData?.TaskIssues?.length;
    let totalSubTasks = taskData?.SubTasks?.length;

    let totalCompletedSubTasks = taskData?.SubTasks?.filter(
      task => task.completed_on,
    )?.length;
    let totalCompletedIssues = taskData?.TaskIssues?.filter(
      issue => issue.completed_on,
    )?.length;

    return totalIssues +
      totalSubTasks +
      totalCompletedSubTasks +
      totalCompletedIssues >
      0
      ? Math.round(
          ((totalCompletedSubTasks + totalCompletedIssues) /
            (totalIssues + totalSubTasks)) *
            100 *
            100,
        ) / 100
      : 0;
  };
  const handleTaskCompleteStatus = e => {
    //getting current completing status
    let isCompleted = taskData?.userTime?.isCompleted;
    //showing up loader
    Block.circle('.task_detail', 'Updating status...');
    //preparing payload
    let payload = {isCompleted: Number(!isCompleted)};
    //initializing network request
    Axios.put(
      `maxproject/task/internal_task/complete_status/${taskId}`,
      payload,
    )
      .then(({data}) => {
        Block.remove('.task_detail');
        Notify.success(data.message, {position: 'right-bottom'});
        setTaskData(old => ({...old, userTime: data?.userTime}));
        //updating tasks
        dispatch(setGlobal({refreshTasks: !refreshTasks}));
      })
      .catch(err => {
        //removing loader
        Block.remove('.task_detail');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const onTaskAttachmentsChange = e => {
    if (!e.target.files) return;
    //showning loader
    Block.circle('.task_detail', 'Uploading attachments...');
    //preparint form data
    let formdata = new FormData();
    Array.from(e.target.files).forEach(file =>
      formdata.append('attachments[]', file),
    );
    //initializing network
    Axios.post(
      'maxproject/task/internal_task/add_task_attachments/' + taskId,
      formdata,
    )
      .then(({data}) => {
        //removing loader
        Notify.success(data.message);
        Block.remove('.task_detail');
        setTaskData(old => ({...old, TaskAttachments: data.TaskAttachments}));
      })
      .catch(err => {
        //removing loader
        Block.remove('.task_detail');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleDeleteAttachment = (i, file) => {
    Confirm.show(
      `Delete ${file.original_name}`,
      `${file.original_name} will be permanently deleted and can't be recovered.`,
      'Delete',
      'Cancel',
      () => {
        //request to delete file from server
        Axios.delete(
          `maxproject/task/internal_task/attachment/${file.id}`,
        ).then(({data}) => {
          Notify.success(data.message);
          setTaskData(old => {
            let TaskAttachments = old.TaskAttachments.slice();
            TaskAttachments.splice(i, 1);
            return {...old, TaskAttachments};
          });
        });
      },
      () => {},
      {},
    );
  };
  const handleTaskDependencyStatus = e => {
    //getting checked status
    let checked = e.target.checked;
    //showing up loader
    Block.circle('.task_detail', 'Updating status...');
    //preparing payload
    let payload = {dependency: Number(checked)};
    //initializing network request
    Axios.put(
      `maxproject/task/internal_task/dependency_status/${taskId}`,
      payload,
    )
      .then(({data}) => {
        Block.remove('.task_detail');
        setTaskData(old => ({...old, dependency: checked}));
      })
      .catch(err => {
        //removing loader
        Block.remove('.task_detail');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const onTaskBlockageChange = payload => {
    //showing up loader
    Block.circle('.task_detail', 'Updating status...');
    //initializing network request
    Axios.put(
      `maxproject/task/internal_task/blockage_status/${taskId}`,
      payload,
    )
      .then(({data}) => {
        Block.remove('.task_detail');
        setTaskData(old => ({...old, payload}));
      })
      .catch(err => {
        //removing loader
        Block.remove('.task_detail');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleEditDesc = () => {
    setEditDesc(true);
  };
  const handleUpdateDesc = () => {
    //getting html from editor state
    const descHtml = draftToHtml(
      convertToRaw(newTaskDesc?.getCurrentContent()),
    );
    Block.circle('.task_desc', 'Saving...');
    //initializing network request
    Axios.put(`maxproject/task/internal_task/desc/${taskId}`, {
      desc: descHtml,
    })
      .then(({data}) => {
        //removing loader
        Block.remove('.task_desc');
        //showing success message
        Notify.success(data.message);
        //removing editor
        setEditDesc(false);
        //updating task state
        setTaskData(old => ({
          ...old,
          desc: descHtml,
        }));
      })
      .catch(err => {
        //removing loader
        Block.remove('.task_desc');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleCancelUpdateDesc = () => {
    setEditDesc(false);
  };
  //? Components
  const TimerComponets = () => {
    const interval = useRef(null);
    //initializing timer
    const [timer, setTimer] = useState(taskData?.userTime || {});

    useEffect(() => {
      if (timer?.status)
        interval.current = setInterval(
          () => setTimer(old => ({...old, duration: old.duration})),
          1000,
        );
      else clearTimeout(interval.current);
      return () => {
        clearTimeout(interval.current);
      };
    }, [timer?.status]);

    const handleTaskTimerStatus = e => {
      //getting checked status
      let checked = e.target.checked;
      //showing up loader
      Block.circle('.task_detail', 'Updating status...');
      //preparing payload
      let payload = {status: Number(checked)};
      //initializing network request
      Axios.put(`maxproject/task/internal_task/timer_status/${taskId}`, payload)
        .then(({data}) => {
          Block.remove('.task_detail');
          //checking for any error message
          if (data?.status == -1)
            Report.info('Attention', data.message, 'Okay');
          else {
            //showing a success message
            Notify.success(`Task ${checked ? 'started' : 'paused'}`, {
              position: 'right-bottom',
            });
            setTaskData(old => ({
              ...old,
              userTime: data?.userTime
                ? {...data?.userTime, status: checked}
                : {...old.userTime, status: checked},
            }));
            //updating tasks
            dispatch(setGlobal({refreshTasks: !refreshTasks}));
          }
        })
        .catch(err => {
          //removing loader
          Block.remove('.task_detail');
          //checking for any error message
          if (err?.response?.data?.message)
            Notify.failure(err?.response?.data?.message);
        });
    };

    return (
      <div className="timer_switch_div_top">
        <div className="timer_switch_div">
          <label
            style={{
              ...(taskData?.isCompleted || taskData?.userTime?.isCompleted
                ? {filter: 'opacity(0.5)'}
                : {}),
            }}
            className="switch">
            <input
              disabled={
                taskData?.isCompleted || taskData?.userTime?.isCompleted
              }
              checked={Boolean(timer?.status)}
              onChange={e => handleTaskTimerStatus(e)}
              type="checkbox"
              className="switch_input"
              readOnly></input>
            <span className="slider round"></span>
          </label>
          <p>{timer?.status ? 'Pause' : 'Start'}</p>
          {/* <MdAvTimer /> */}
          {timer?.status ? (
            <AccessTimeIcon fontSize="medium" style={{color: 'black'}} />
          ) : (
            <SnoozeIcon
              color="black"
              fontSize="medium"
              style={{color: 'black'}}
            />
          )}
          <span style={{color: timer?.status ? 'blue' : 'green'}}>
            {calDuration(timer)}
          </span>
        </div>
      </div>
    );
  };
  const TeamMembersUiModal = () => {
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      border: '0px solid #000',
      boxShadow: 24,
      p: 0,
    };
    const TeamMembersDataUi = props => (
      <div className="team_members_data_ui_div">
        <img
          src={
            props.userIcon ||
            'https://source.unsplash.com/random/500x500/?user,icon'
          }
          alt="img"
        />
        {props.name}
      </div>
    );
    const TeamMembersData = [
      {
        value: '1',
        label: <TeamMembersDataUi name="sunny charkhwal" />,
      },
      {
        value: '2',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face2"
          />
        ),
      },
      {
        value: '3',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face3"
          />
        ),
      },
      {
        value: '4',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face4"
          />
        ),
      },
      {
        value: '5',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face5"
          />
        ),
      },
      {
        value: '6',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face6"
          />
        ),
      },
      {
        value: '7',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face7"
          />
        ),
      },
      {
        value: '8',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face8"
          />
        ),
      },
      {
        value: '9',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face9"
          />
        ),
      },
      {
        value: '10',
        label: (
          <TeamMembersDataUi
            name="sunny charkhwal"
            userIcon="https://source.unsplash.com/random/500x500/?user,face10"
          />
        ),
      },
    ];
    const TeamMembersShowSearchResultData = [
      {
        Img: '',
        userName: 'kamal',
      },
      {
        Img: 'https://source.unsplash.com/random/500x500/?face,photo',
        userName: 'sunny charkhwal',
      },
      {
        Img: 'https://source.unsplash.com/random/500x500/?face,photo',
        userName: 'sunny charkhwal',
      },
    ];
    return (
      <Modal
        open={showTeamModal}
        onClose={handleCloseTeamModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="team_members_ui_modal">
          <div className="team_members_ui_modal_title">
            <h3>Team Members</h3>
            <IoClose onClick={handleCloseTeamModal} />
          </div>
          <div className="team_members_ui_modal_body">
            <div className=" mb-4">
              <Select
                // menuIsOpen={true}
                options={TeamMembersData}
                placeholder="Team Members"
                isMulti
              />
            </div>
            <div
              className="
            _scorll">
              <div className="row g-3">
                {TeamMembersShowSearchResultData.map((val, i) => (
                  <div className="col-12" key={i}>
                    <div className="team_members_data_ui_div">
                      <img
                        src={
                          val.Img ||
                          'https://source.unsplash.com/random/500x500/?girl'
                        }
                        alt="img"
                      />
                      {val.userName}
                      <span>Remove</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    );
  };
  // Header Menu
  const HeaderMenu = () => {
    const [taskRightDrawerUiHeaderMenu, setTaskRightDrawerUiHeaderMenu] =
      useState(null);
    const open = Boolean(taskRightDrawerUiHeaderMenu);
    const handleClick = event => {
      setTaskRightDrawerUiHeaderMenu(event.currentTarget);
    };
    const handleClose = () => {
      setTaskRightDrawerUiHeaderMenu(null);
    };
    const navigate = useNavigate();

    const confirmDeleteTask = () => {
      Confirm.show(
        'Delete Task',
        "This action can't be undone",
        'Yes',
        'No',
        () => {
          handleDeleteTask(taskId);
        },
      );
    };

    return (
      <div>
        <input
          type="file"
          name="attachments"
          style={{display: 'none'}}
          multiple={true}
          id="attachemnt_input"
          onChange={onTaskAttachmentsChange}
        />
        <Box onClick={handleClick}>
          <BiDotsHorizontal className="menu_svg" />
        </Box>
        <Menu
          anchorEl={taskRightDrawerUiHeaderMenu}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{horizontal: 'right', vertical: 'top'}}
          anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
          <MenuItem
            onClick={() => navigate(`/Management/update_task/${taskId}`)}>
            <ListItemIcon>
              <EditOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem onClick={confirmDeleteTask}>
            <ListItemIcon>
              <DeleteOutlineIcon fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>
          <MenuItem>
            <label htmlFor="attachemnt_input">
              <ListItemIcon>
                <AttachFileIcon fontSize="small" />
              </ListItemIcon>
              Attach Document
            </label>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <ApprovalOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Send for approval
          </MenuItem>
        </Menu>
        <TeamMembersUiModal />
      </div>
    );
  };
  const TaskTabs = () => {
    const SubTasks = () => {
      const [subTaskName, setSubTaskName] = useState('');
      const [subTasks, setSubTasks] = useState(taskData?.SubTasks || []);

      const handleSubTaskChange = (checked, subTaskId, idx) => {
        const proceedChange = () => {
          //showing up loader
          Block.circle('.sub_tasks', 'Loading...');
          Axios.put(`maxproject/sub_task/${subTaskId}/update_complete_status`, {
            isCompleted: checked ? 1 : 0,
          })
            .then(({data}) => {
              Block.remove('.sub_tasks');
              // Notify.success(data.message);
              setSubTasks(old => {
                let newData = old.slice();
                newData[idx] = data.data;
                return newData;
              });
            })
            .catch(err => {
              //removing loader
              Block.remove('.sub_tasks');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        Confirm.show(
          `Mark as completed?`,
          "This action can't be undone",
          'Yes',
          'No',
          proceedChange,
        );
      };
      const handeAddNewSubTask = e => {
        if (e.key && (e.key !== 'Enter' || !subTaskName.length)) return;
        //showing up loader
        Block.circle('.sub_tasks', 'Loading...');
        //preparing payload
        const payload = {
          name: subTaskName,
        };
        //initializing network request
        Axios.post(`maxproject/sub_task/${taskId}`, payload)
          .then(({data}) => {
            Block.remove('.sub_tasks');
            Notify.success(data.message);
            setSubTaskName('');
            setSubTasks(old => {
              let newData = old.slice();
              newData.push(data.task);
              return newData;
            });
          })
          .catch(err => {
            //removing loader
            Block.remove('.sub_tasks');
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      };
      const handleDelete = (id, idx) => {
        const proceedDelete = () => {
          //showing up loader
          Block.circle('.sub_tasks', 'Loading...');
          //initializing network request
          Axios.delete(`maxproject/sub_task/${id}`)
            .then(({data}) => {
              Block.remove('.sub_tasks');
              Notify.success(data.message);
              setSubTasks(old => {
                let newData = old.slice();
                newData.splice(idx, 1);
                return newData;
              });
            })
            .catch(err => {
              //removing loader
              Block.remove('.sub_tasks');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        Confirm.show(
          'Confirm Delete',
          "This action can't be undone",
          'Delete',
          'Cancel',
          proceedDelete,
        );
      };
      const SubTask = ({sub, idx}) => {
        const [isEditing, setIsEditing] = useState(false);
        const [name, setName] = useState(sub.name);
        const handeUpdateSubTask = e => {
          if (e.key && (e.key !== 'Enter' || !name.length)) return;
          //showing up loader
          Block.circle('.sub_tasks', 'Loading...');
          //preparing payload
          const payload = {
            name,
          };
          //initializing network request
          Axios.put(`maxproject/sub_task/${sub.id}`, payload)
            .then(({data}) => {
              Block.remove('.sub_tasks');
              Notify.success(data.message);

              setSubTasks(old => {
                let newData = old.slice();
                newData[idx].name = name;
                return newData;
              });
            })
            .catch(err => {
              //removing loader
              Block.remove('.sub_tasks');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        return (
          <ul className="issues_top_new_div" key={idx}>
            <li>
              {isEditing ? (
                <div className="">
                  <input
                    className="form-control intput_box my-2"
                    type="text"
                    value={name}
                    style={{height: 30}}
                    onChange={e => setName(e.target.value)}
                    onKeyDown={handeUpdateSubTask}
                    placeholder="Update task"></input>
                </div>
              ) : (
                <FormControlLabel
                  label={
                    Boolean(sub.completed_on) ? (
                      <strike style={{color: 'gray'}}>{sub?.name}</strike>
                    ) : (
                      sub?.name
                    )
                  }
                  control={
                    <Checkbox
                      readOnly
                      disabled={Boolean(sub.completed_on)}
                      onChange={e =>
                        handleSubTaskChange(e.target.checked, sub.id, idx)
                      }
                      checked={Boolean(sub.completed_on)}
                    />
                  }
                />
              )}
            </li>
            <li>
              <div
                className="issues_top_new_btn_div"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                {!Boolean(sub.completed_on) ? (
                  isEditing ? (
                    <>
                      <span
                        onClick={handeUpdateSubTask}
                        style={{
                          marginLeft: 10,
                          color: '#1a7fdd',
                          cursor: 'pointer',
                        }}>
                        Save
                      </span>
                      <span
                        onClick={() => setIsEditing(false)}
                        style={{
                          marginLeft: 10,
                          color: '#1a7fdd',
                          cursor: 'pointer',
                        }}>
                        Cancel
                      </span>
                    </>
                  ) : (
                    <button className="me-1">
                      <FiEdit onClick={() => setIsEditing(!isEditing)} />
                    </button>
                  )
                ) : null}
                {isEditing || Boolean(sub.completed_on) ? null : (
                  <button className="ms-1">
                    <RiDeleteBinLine
                      onClick={() => handleDelete(sub.id, idx)}
                    />
                  </button>
                )}
                {Boolean(sub.completed_on) ? (
                  <i>
                    <small
                      style={{
                        color: 'gray',
                        fontSize: 12,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      completed on ({moment(sub?.completed_on).format('DD MMM')}
                      ) by
                      <div className="task_details_user_avatar">
                        <Avatar
                          alt={sub.CompletedBy?.name}
                          src={sub.CompletedBy?.avatar}
                          sx={{width: 15, height: 15}}
                        />
                        <span>{sub.CompletedBy?.name}</span>
                      </div>
                    </small>
                  </i>
                ) : null}
                {!Boolean(sub.completed_on) && !isEditing ? (
                  <i>
                    <span
                      style={{
                        color: 'gray',
                        fontSize: 12,
                        marginLeft: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      added on {moment(sub?.createdAt).format('DD MMM')}{' '}
                      {sub.CreatedBy ? (
                        <>
                          by
                          <div className="task_details_user_avatar">
                            <Avatar
                              alt={sub.CreatedBy?.name}
                              src={sub.CreatedBy?.avatar}
                              sx={{width: 15, height: 15}}
                            />
                            <span>{sub.CreatedBy?.name}</span>
                          </div>
                        </>
                      ) : null}
                    </span>
                  </i>
                ) : null}
              </div>
            </li>
          </ul>
        );
      };
      return (
        <div className="task_right_drawer_ui_body_description sub_tasks">
          <FormGroup>
            {subTasks?.map((sub, idx) => (
              <SubTask sub={sub} idx={idx} />
            ))}
          </FormGroup>
          <div className="task_right_drawer_ui_body_Subtasks_input_div">
            <input
              className="form-control intput_box"
              type="text"
              value={subTaskName}
              onChange={e => setSubTaskName(e.target.value)}
              onKeyDown={handeAddNewSubTask}
              placeholder="Create new sub task"></input>
            {/* <RiDeleteBinLine /> */}
          </div>

          <button
            disabled={!subTaskName.length}
            onClick={handeAddNewSubTask}
            className="text_button mt-3">
            <AiOutlinePlus />
            Add Subtask
          </button>
        </div>
      );
    };
    const Issues = () => {
      const [issueName, setIssueName] = useState('');
      const [issues, setIssues] = useState(taskData?.TaskIssues || []);

      const handleIssueChange = (checked, subTaskId, idx) => {
        const proceedChange = () => {
          //showing up loader
          Block.circle('.issues', 'Loading...');
          Axios.put(
            `maxproject/task_issue/${subTaskId}/update_complete_status`,
            {
              isCompleted: checked ? 1 : 0,
            },
          )
            .then(({data}) => {
              Block.remove('.issues');
              // Notify.success(data.message);
              setIssues(old => {
                let newData = old.slice();
                newData[idx] = data.data;
                return newData;
              });
            })
            .catch(err => {
              //removing loader
              Block.remove('.issues');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };

        Confirm.show(
          'Mark as completed?',
          "This action can't be undone",
          'Yes',
          'No',
          proceedChange,
        );
      };
      const handleAddNewIssue = e => {
        if (e.key && (e.key !== 'Enter' || !issueName.length)) return;
        //showing up loader
        Block.circle('.issues', 'Loading...');
        //preparing payload
        const payload = {
          name: issueName,
        };
        //initializing network request
        Axios.post(`maxproject/task_issue/${taskId}`, payload)
          .then(({data}) => {
            Block.remove('.issues');
            Notify.success(data.message);
            setIssueName('');
            setIssues(old => {
              let newData = old.slice();
              newData.push(data.issue);
              return newData;
            });
          })
          .catch(err => {
            //removing loader
            Block.remove('.issues');
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      };
      const handleDelete = (id, idx) => {
        const proceedDelete = () => {
          //showing up loader
          Block.circle('.issues', 'Loading...');
          //initializing network request
          Axios.delete(`maxproject/task_issue/${id}`)
            .then(({data}) => {
              Block.remove('.issues');
              Notify.success(data.message);
              setIssues(old => {
                let newData = old.slice();
                newData.splice(idx, 1);
                return newData;
              });
            })
            .catch(err => {
              //removing loader
              Block.remove('.issues');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        Confirm.show(
          'Confirm Delete',
          "This action can't be undone",
          'Delete',
          'Cancel',
          proceedDelete,
        );
      };
      const Issue = ({issue, idx}) => {
        const [isEditing, setIsEditing] = useState(false);
        const [name, setName] = useState(issue.name);
        const handleUpdateIssue = e => {
          if (e.key && (e.key !== 'Enter' || !name.length)) return;
          //showing up loader
          Block.circle('.issues', 'Loading...');
          //preparing payload
          const payload = {
            name,
          };
          //initializing network request
          Axios.put(`maxproject/task_issue/${issue.id}`, payload)
            .then(({data}) => {
              Block.remove('.issues');
              Notify.success(data.message);

              setIssues(old => {
                let newData = old.slice();
                newData[idx].name = name;
                return newData;
              });
            })
            .catch(err => {
              //removing loader
              Block.remove('.issues');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        return (
          <ul className="issues_top_new_div" key={idx}>
            <li>
              {isEditing ? (
                <div className="">
                  <input
                    className="form-control intput_box my-2"
                    type="text"
                    value={name}
                    style={{height: 30}}
                    onChange={e => setName(e.target.value)}
                    onKeyDown={handleUpdateIssue}
                    placeholder="Update issue"></input>
                </div>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      readOnly
                      disabled={Boolean(issue.completed_on)}
                      onChange={e =>
                        handleIssueChange(e.target.checked, issue?.id, idx)
                      }
                      checked={Boolean(issue?.completed_on)}
                    />
                  }
                  label={
                    Boolean(issue?.completed_on) ? (
                      <strike style={{color: 'gray'}}>{issue?.name}</strike>
                    ) : (
                      issue?.name
                    )
                  }
                />
              )}
            </li>
            <li>
              <div
                className="issues_top_new_btn_div"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                {!Boolean(issue.completed_on) ? (
                  isEditing ? (
                    <>
                      <span
                        onClick={handleUpdateIssue}
                        style={{
                          marginLeft: 10,
                          color: '#1a7fdd',
                          cursor: 'pointer',
                        }}>
                        Save
                      </span>
                      <span
                        onClick={() => setIsEditing(false)}
                        style={{
                          marginLeft: 10,
                          color: '#1a7fdd',
                          cursor: 'pointer',
                        }}>
                        Cancel
                      </span>
                    </>
                  ) : (
                    <button className="me-1">
                      <FiEdit onClick={() => setIsEditing(!isEditing)} />
                    </button>
                  )
                ) : null}
                {isEditing || Boolean(issue.completed_on) ? null : (
                  <button className="ms-1">
                    <RiDeleteBinLine
                      onClick={() => handleDelete(issue.id, idx)}
                    />
                  </button>
                )}
                {Boolean(issue.completed_on) ? (
                  <i>
                    <small
                      style={{
                        color: 'gray',
                        fontSize: 12,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      completed on (
                      {moment(issue?.completed_on).format('DD MMM')}) by
                      <div className="task_details_user_avatar">
                        <Avatar
                          alt={issue?.CompletedBy?.name}
                          src={issue?.CompletedBy?.avatar}
                          sx={{width: 15, height: 15}}
                        />
                        <span>{issue?.CompletedBy?.name}</span>
                      </div>
                    </small>
                  </i>
                ) : null}
                {!Boolean(issue.completed_on) && !isEditing ? (
                  <i>
                    <span
                      style={{
                        color: 'gray',
                        fontSize: 12,
                        marginLeft: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      added on {moment(issue?.createdAt).format('DD MMM')}{' '}
                      {issue?.CreatedBy ? (
                        <>
                          by
                          <div className="task_details_user_avatar">
                            <Avatar
                              alt={issue.CreatedBy?.name}
                              src={issue.CreatedBy?.avatar}
                              sx={{width: 15, height: 15}}
                            />
                            <span>{issue.CreatedBy?.name}</span>
                          </div>
                        </>
                      ) : null}
                    </span>
                  </i>
                ) : null}
              </div>
            </li>
          </ul>
        );
      };
      return (
        <div className="task_right_drawer_ui_body_description issues">
          <FormGroup>
            {issues?.map((issue, idx) => (
              <Issue issue={issue} idx={idx} />
            ))}
          </FormGroup>
          <div className="task_right_drawer_ui_body_Subtasks_input_div">
            <input
              className="form-control intput_box"
              type="text"
              value={issueName}
              onChange={e => setIssueName(e.target.value)}
              onKeyDown={handleAddNewIssue}
              placeholder="Create new issue"></input>
            {/* <RiDeleteBinLine /> */}
          </div>
          <button
            disabled={!issueName.length}
            onClick={handleAddNewIssue}
            className="text_button mt-3">
            <AiOutlinePlus />
            Add New Issue
          </button>
        </div>
      );
    };
    const Comments = () => {
      const [comments, setComments] = useState(taskData?.TaskComments || []);
      const handleDeleteComment = (commentId, idx) => {
        const processDeleteComment = () => {
          //blocking comment section
          Block.dots('#comment-' + commentId);
          //initializing network request
          Axios.delete(`maxproject/task_comment/${commentId}`)
            .then(({data}) => {
              //removing loader
              Block.remove('#comment-' + commentId);
              //removing comment from state
              setComments(old => {
                let arr = old.slice();
                arr.splice(idx, 1);
                return arr;
              });
              //showinng popup
              Notify.success(data.message);
            })
            .catch(err => {
              //removing loader
              Block.remove('#comment-' + commentId);
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        Confirm.show(
          'Delete Comment',
          "This action can't be undone",
          'Yes',
          'No',
          processDeleteComment,
        );
      };
      const FilePreview = ({name, url, handleRemoveFile}) => (
        <div className="col-12">
          <div className="comments_typing_Attach_row">
            <div
              className="comments_typing_Attach_icon"
              style={{
                backgroundImage: `url(${url})`,
              }}></div>
            <p>{name}</p>
            <RiDeleteBinLine onClick={handleRemoveFile} />
          </div>
        </div>
      );
      const EditView = ({idx, comment, setIsEditing}) => {
        const [text, setText] = useState(comment.comment);

        const handleUpdateComment = () => {
          //blocking comment section
          Block.dots('#comment-' + comment.id);
          //initializing network request
          Axios.put(`maxproject/task_comment/${taskId}`, {
            comment: text,
            comment_id: comment.id,
          })
            .then(({data}) => {
              //removing loader
              Block.remove('#comment-' + comment.id);
              //removing editor
              setIsEditing(false);
              //updating comment from state
              setComments(old => {
                let arr = old.slice();
                arr[idx].comment = text;
                return arr;
              });
              //showinng popup
              Notify.success(data.message);
            })
            .catch(err => {
              //removing loader
              Block.remove('#comment-' + comment.id);
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        return (
          <>
            <form
              method="post"
              encType="multipart/form-data"
              className="comments_typing_div pb-3 pt-md-3 pt-2 new_comment"
              // onSubmit={handleNewComment}
            >
              <div className=" w-100">
                <div className="comments_typing_inner_div">
                  <div className=" p-2">
                    <TextField
                      id="standard-multiline-static"
                      multiline
                      name="comment"
                      fullWidth
                      value={text}
                      onChange={e => setText(e.target.value)}
                      rows={7}
                      placeholder="Enter your comment"
                      variant="standard"
                    />
                  </div>
                </div>
              </div>
            </form>
            <div style={{marginBottom: '1rem'}}>
              <span
                onClick={handleUpdateComment}
                style={{
                  marginLeft: 10,
                  color: '#1a7fdd',
                  cursor: 'pointer',
                }}>
                Save
              </span>
              <span
                onClick={() => setIsEditing(false)}
                style={{
                  marginLeft: 10,
                  color: '#1a7fdd',
                  cursor: 'pointer',
                }}>
                Cancel
              </span>
            </div>
          </>
        );
      };

      const Comment = ({idx, comment, children}) => {
        const [isEditing, setIsEditing] = useState(false);

        return (
          <div className="comments_menu_top_div" id={`comment-${comment.id}`}>
            <ul className="comments_user_ui">
              <li>
                <img src={comment?.User?.avatar} alt="user" />
              </li>
              <li className="comments_menu_outer_div">{comment?.User?.name}</li>
            </ul>
            <div className="comments_img_inner">
              {isEditing ? (
                <EditView
                  idx={idx}
                  comment={comment}
                  setIsEditing={setIsEditing}
                />
              ) : (
                <div>
                  {children}
                  <div>
                    <ul className="comments_menu_time_div">
                      <li className="comments_menu_time">
                        {moment(comment?.createdAt).format('DD MMM')}
                        <span className="comments_menu_time_div_inner">
                          <div className="d-flex">
                            <button
                              className="comments_menu_btn"
                              onClick={() => setIsEditing(true)}>
                              Edit
                            </button>
                            <button
                              className="comments_menu_btn"
                              onClick={() =>
                                handleDeleteComment(comment.id, idx)
                              }>
                              Delete
                            </button>
                          </div>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      };
      const CommentText = ({text}) => {
        const URL_REGEX =
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        // const linkRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
        const renderText = () =>
          text.split(' ').map(part =>
            URL_REGEX.test(part) ? (
              <a style={{wordBreak: 'break-all'}} href={part} target="_blank">
                {part}{' '}
              </a>
            ) : (
              part + ' '
            ),
          );
        return <div className="comments_user_ui_text">{renderText()}</div>;
      };
      const CommentDocs = ({docs = []}) => (
        <div className="comments_doc_div">
          {docs.map(doc =>
            doc.type == 'image/jpeg' || doc.type == 'image/png' ? (
              <div className="comments_img_div">
                <div
                  className="comments_img"
                  style={{backgroundImage: `url('${doc.url}')`}}></div>
                <div className="comments_img_name">{doc.original_name}</div>
                <div className="comments_img_name_download_div">
                  <div className="comments_img_name_download">
                    <NavLink to={doc.url} target="_blank" download>
                      <span>
                        <HiOutlineDownload />
                      </span>
                    </NavLink>
                  </div>
                </div>
              </div>
            ) : (
              <div className="new_comments_doc_div">
                <div className="new_comments_doc_img_top_div">
                  <div className="new_comments_doc_img_div">
                    <div
                      className="new_comments_doc_div_img"
                      style={{
                        backgroundImage: `url(${getFileIcon(doc)})`,
                      }}></div>
                  </div>
                  <div className="new_comments_doc_text_div">
                    <p>{doc.original_name}</p>
                  </div>
                  <div className="new_comments_doc_download_div">
                    <NavLink to={doc.url} target="_blank" download>
                      <span>
                        <HiOutlineDownload />
                      </span>
                    </NavLink>
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      );
      const NewComment = () => {
        const [showCommentOptions, setShowCommentOptions] = useState(false);
        const [fileInput, setFileInput] = useState([]);
        const [comment, setComment] = useState('');
        const handleNewComment = e => {
          e.preventDefault();
          //blocking comment section
          Block.circle('.new_comment');
          //preparing form data
          let formdata = new FormData();
          //adding form data
          formdata.append('comment', comment);
          //adding attachments
          Array.from(fileInput).forEach(file =>
            formdata.append('attachments[]', file),
          );
          //initializing network request
          Axios.post(`maxproject/task_comment/${taskId}`, formdata)
            .then(({data}) => {
              //removing loader
              Block.remove('.new_comment');
              //reseting fields
              setComment('');
              setFileInput([]);
              setShowCommentOptions(false);
              setComments(old => [...old, data?.newComment]);
              //showinng popup
              Notify.success(data.message);
            })
            .catch(err => {
              //removing loader
              Block.remove('.new_comment');
              //checking for any error message
              if (err?.response?.data?.message)
                Notify.failure(err?.response?.data?.message);
            });
        };
        function removeFileFromList(fileToRemove) {
          const filesArray = Array.from(fileInput);
          const updatedFilesArray = filesArray.filter(
            file => file !== fileToRemove,
          );
          setFileInput(updatedFilesArray);
        }
        const onAttachmentChange = e => {
          let files = e.target.files;
          setFileInput(files);
        };
        const CommentOptions = () => (
          <div className="comments_typing_btn_div">
            {Array.from(fileInput).length ? (
              <div className="comments_typing_Attach_div">
                <p>Attachments</p>
                <div className="comments_typing_Attach_scroll">
                  <div className="row">
                    {Array.from(fileInput).map((file, i) => (
                      <FilePreview
                        key={i}
                        name={file.name}
                        url={getFileIcon(file)}
                        handleRemoveFile={() => removeFileFromList(file)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="comments_typing_btn_top_div">
              <div className="row align-items-center">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                  <label
                    htmlFor="comment_attachemnt_input"
                    className="comments_typing_btn_attach">
                    <IoIosAttach />
                    Attach Files
                  </label>
                  <input
                    type="file"
                    name="attachments"
                    style={{display: 'none'}}
                    multiple={true}
                    id="comment_attachemnt_input"
                    onChange={onAttachmentChange}
                  />
                </div>
                <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                  <div className="comments_typing_btn_inner_div">
                    <FullBtn name="Add Comment" />
                    <OutlineBtn
                      onClick={() => setShowCommentOptions(false)}
                      name="Cancel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        return (
          <form
            method="post"
            encType="multipart/form-data"
            className="comments_typing_div pb-3 pt-md-3 pt-2 new_comment"
            onSubmit={handleNewComment}>
            <div
              className="comments_img"
              style={{backgroundImage: `url(${user.avatar})`}}></div>
            <div className=" w-100">
              <div className="comments_typing_inner_div">
                <div className=" p-2">
                  <TextField
                    id="standard-multiline-static"
                    multiline
                    name="comment"
                    fullWidth
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    rows={7}
                    placeholder="Enter your comment"
                    variant="standard"
                    onFocus={() => setShowCommentOptions(true)}
                  />
                </div>
              </div>
              {showCommentOptions ? <CommentOptions /> : null}
            </div>
          </form>
        );
      };
      return (
        <div className="comments">
          {comments.map((comment, i) => (
            <Comment key={i} idx={i} comment={comment}>
              <CommentText text={comment?.comment} />
              <CommentDocs docs={comment?.TaskCommentAttachments} />
            </Comment>
          ))}
          <NewComment />
        </div>
      );
    };
    const NewSubTasks = () => {
      const [isActive, setIsActive] = useState(false);
      const [markComplete, setMarkComplete] = useState(false);
      const [listEdite, setListEdit] = useState(false);
      const label = {inputProps: {'aria-label': 'Checkbox demo'}};
      const ActiveCheckBoxHandleClick = () => {
        setIsActive(!isActive);
      };
      const style = {
        backgroundColor: isActive ? 'rgba(15, 98, 254, 0.08)' : null,
      };
      const ListMenu = () => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = event => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        return (
          <React.Fragment>
            <Box
              sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
              <Tooltip title="Action">
                <MoreVertOutlinedIcon
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  className="new_sub_task_action_btn"
                />
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
              <MenuItem
                onClick={setListEdit}
                className="new_sub_task_action_inner_btn">
                <MdOutlineEdit />
                Edit
              </MenuItem>
              <MenuItem
                className="new_sub_task_action_inner_btn"
                onClick={() => {
                  Confirm.show(
                    `Delete?`,
                    `This action is undonable!`,
                    'Cancel',
                    'Delete',
                    () => {},
                    () => {},
                  );
                }}>
                <RiDeleteBin6Line />
                Delete
              </MenuItem>
              <MenuItem
                className="new_sub_task_action_inner_btn"
                onClick={() => {
                  Confirm.show(
                    `Mark complete?`,
                    `This action is undonable!`,
                    'No',
                    'Yes',
                    () => {},
                    () => {
                      setMarkComplete();
                    },
                  );
                }}>
                <MdCheck />
                {markComplete ? <>Completed</> : <> Mark complete</>}
              </MenuItem>
              <MenuItem
                className="new_sub_task_action_inner_btn"
                onClick={handleClose}>
                <MdApproval /> Send for approval
              </MenuItem>
            </Menu>
          </React.Fragment>
        );
      };
      return (
        <>
          {/* add New SubTasks ui */}
          <div className="row mb-3 g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 new_sub_task_add_ui">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 new_sub_task_add_ui_box">
              <InputBox
                type="text"
                label="Add subtasks"
                placeholder="Enter sub task name"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <InputBox
                type="date"
                label="Start Date*"
                // errorMessage="Please enter start date"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
              <InputBox
                type="date"
                label="end Date*"
                // errorMessage="end date is required"
              />
            </div>
            <div className="col-12">
              <Button className="new_sub_task_add_btn" variant="contained">
                Add
              </Button>
            </div>
          </div>
          {/* add New SubTasks ui end */}
          <div className="new_sub_task_main_div">
            <div className="new_sub_task_top_div">
              {/* list header btn */}
              {isActive ? (
                <div className="new_sub_task_outer_btn_div scale-up-center">
                  <Button
                    variant="text"
                    className="new_sub_task_top_div_btn"
                    onClick={setMarkComplete}>
                    <MdCheck />
                    {markComplete ? <>Completed</> : <> Mark complete</>}
                  </Button>
                  <Button
                    variant="text"
                    className="new_sub_task_top_div_btn2"
                    onClick={() => {
                      Confirm.show(
                        `Delete?`,
                        `This action is undonable!`,
                        'Cancel',
                        'Delete',
                        () => {},
                        () => {},
                      );
                    }}>
                    <RiDeleteBin6Line /> Delete
                  </Button>
                  <Button variant="text" className="new_sub_task_top_div_btn3">
                    <MdApproval /> Send for approval
                  </Button>
                </div>
              ) : null}
              {/* list header btn end */}
              <div className="row">
                {/* list header */}
                <div className="col-12">
                  <div className="row new_sub_task_title_row">
                    <div className="col-1">
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...label}
                            onChange={ActiveCheckBoxHandleClick}
                          />
                        }
                      />
                    </div>
                    <div className="col-6 new_sub_task_title_col">
                      <div className="new_sub_task_title">Subtasks</div>
                    </div>
                    <div className="col-2 new_sub_task_title_col new_sub_task_title_col2">
                      <div className="new_sub_task_title ">start date</div>
                    </div>
                    <div className="col-2 new_sub_task_title_col new_sub_task_title_col2">
                      <div className="new_sub_task_title new_sub_task_title_col2">
                        end date
                      </div>
                    </div>
                    <div className="col-1 new_sub_task_title_col new_sub_task_title_col2">
                      <div className="new_sub_task_title">Action</div>
                    </div>
                  </div>
                </div>
                {/* list header end */}
                <div className="col-12 p-0">
                  <div className="new_sub_task_list_scroll_div">
                    {/* list item row */}
                    <div className="row m-0">
                      <div
                        className="col-12 new_sub_task_list_top_div"
                        style={style}>
                        {listEdite ? (
                          // list item normal edite ul
                          <>
                            <div className="new_sub_task_list_item_edite">
                              <div className="input1">
                                <InputBox
                                  type="text"
                                  label="title"
                                  placeholder="Enter sub task name"
                                />
                              </div>
                              <div className="input2">
                                <InputBox type="date" label="start date" />
                              </div>
                              <div className="input2">
                                <InputBox type="date" label="end date" />
                              </div>
                            </div>
                            <div className=" new_sub_task_list_item_edite_btn ">
                              <Button
                                variant="text"
                                className="new_sub_task_top_div_btn"
                                onClick={() => {
                                  setListEdit(false);
                                }}>
                                <MdCheck />
                              </Button>
                              <Button
                                variant="text"
                                className="new_sub_task_top_div_btn"
                                onClick={() => {
                                  setListEdit(false);
                                }}>
                                <MdOutlineClose />
                              </Button>
                            </div>
                          </>
                        ) : (
                          // list item normal edite ul
                          // list item normal ul
                          <div className="row ">
                            <div className="col-1 new_sub_task_list_checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...label}
                                    onChange={ActiveCheckBoxHandleClick}
                                  />
                                }
                              />
                            </div>
                            <div className="col-7 ">
                              <div className="new_sub_task_list_title_div">
                                <div className="new_sub_task_list_title_bottom_div mb-2">
                                  <span
                                    className="new_sub_task_list_badge"
                                    style={{
                                      backgroundColor: markComplete
                                        ? '#F0F0F0'
                                        : '#D9E8D3',
                                      color: markComplete
                                        ? '#1C1B1F'
                                        : '#FF0000',
                                    }}>
                                    {markComplete ? (
                                      <>Completed</>
                                    ) : (
                                      <>self approved</>
                                    )}
                                  </span>
                                  <span>
                                    by Deependra Bhaskar on 1:44 PM, 1/3/2024
                                  </span>
                                </div>
                                <p>
                                  {markComplete ? (
                                    <del>
                                      Design an implement all the visual
                                      hierarchy on the design
                                    </del>
                                  ) : (
                                    <>
                                      Design an implement all the visual
                                      hierarchy on the design
                                    </>
                                  )}
                                </p>
                                <div className="new_sub_task_list_title_bottom_div mt-2">
                                  <span>
                                    {markComplete ? (
                                      <>Completed</>
                                    ) : (
                                      <>Created</>
                                    )}{' '}
                                    by
                                  </span>
                                  <Avatar
                                    alt="Deependra Bhaskar"
                                    src="https://i.pravatar.cc/150?img=12"
                                    sx={{width: 24, height: 24}}
                                    className="ms-1 me-1"
                                  />
                                  <span>
                                    Deependra Bhaskar on 1:44 pm, 1/3/2024
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-2 new_sub_task_list_date  justify-content-start">
                              <div style={{marginLeft: '-13px'}}>1/3/2024</div>
                            </div>
                            <div className="col-1 new_sub_task_list_date">
                              <div style={{marginLeft: '-13px'}}>1/3/2024</div>
                            </div>
                            <div className="col-1 new_sub_task_list_date">
                              <div>
                                <ListMenu />
                              </div>
                            </div>
                          </div>
                          // list item normal ul end
                        )}
                      </div>
                    </div>
                    {/* list item row end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };
    return (
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="task_right_drawer_ui_body_tab">
          <Nav variant="pills" className="d-flex">
            <Nav.Item>
              <Nav.Link eventKey="first">
                <TbSubtask />
                Subtasks
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
                <GoIssueOpened />
                Issues
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Comments">
                <MdOutlineComment />
                Comments
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className=" mt-3">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <NewSubTasks />
              <SubTasks />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <Issues />
            </Tab.Pane>
            <Tab.Pane eventKey="Comments">
              <Comments />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  };

  return (
    <div className="task_right_drawer_ui_main_div task_detail">
      <div className="task_right_drawer_ui_header_div">
        <IoClose
          className="close_svg"
          onClick={() => dispatch(setGlobal({showTask: false, taskId: null}))}
        />
        <div className="task_right_drawer_ui_inner_div">
          <button
            onClick={handleTaskCompleteStatus}
            className={
              taskData?.userTime?.isCompleted
                ? 'task_details_complete_btn task_details_complete_btn2'
                : 'task_details_complete_btn'
            }>
            {taskData?.userTime?.isCompleted ? (
              <>
                <FaCheck />
                <span>completed</span>
              </>
            ) : (
              <>
                <FaCheck />
                <span>Mark complete</span>
              </>
            )}
          </button>
          <TimerComponets />
          <NavLink
            onClick={() => dispatch(setGlobal({showTask: false, taskId: null}))}
            to={`/Management/${
              taskData?.Project ? 'update_task' : 'update_other_task'
            }/${taskId}`}>
            <MdOutlineModeEditOutline />
          </NavLink>
          <HeaderMenu />
        </div>
      </div>
      <div className="task_right_drawer_ui_body_div">
        {taskData?.Project ? (
          <div className="task_right_drawer_ui_body_nav_div">
            <p>{taskData?.Project?.name}</p>
            <span>
              <FiChevronRight />
            </span>
            <p>{taskData?.Department?.name}</p>
            <span>
              <FiChevronRight />
            </span>
            <p>{taskData?.ProjectService?.Service?.name}</p>
            <span>
              <FiChevronRight />
            </span>
            <p>{taskData?.Milestone?.title}</p>
          </div>
        ) : null}
        <div className="task_right_drawer_ui_body_project_title">
          <p>{taskData?.name}</p>
          <div className=" d-flex mt-1">
            <div className="task_right_drawer_ui_body_user_add">
              Added by <b>{taskData?.User?.name}</b>
            </div>
            <div className="task_right_drawer_ui_body_user_add_date">
              {moment(taskData?.createdAt).format('DD MMM')}
            </div>
          </div>
        </div>
        <ul className="task_right_drawer_ui_body_created">
          <li className="li_1">
            <p>Created By</p>
            <div className="team_members_data_ui_div">
              <img src={taskData?.User?.avatar} alt="img" />
              <p
                className="mb-0 ms-2 "
                style={{fontSize: '16px', color: 'black'}}>
                {taskData?.User?.name}
              </p>
            </div>
          </li>
          {taskData?.TaskOwner ? (
            <li className="li_1">
              <p>Owner</p>
              <div className="team_members_data_ui_div">
                <img src={taskData?.TaskOwner?.User?.avatar} alt="img" />
                <p
                  className="mb-0 ms-2 "
                  style={{fontSize: '16px', color: 'black'}}>
                  {taskData?.TaskOwner?.User?.name}
                </p>
              </div>
            </li>
          ) : null}
          <li className=" ms-md-3 ms-0 mt-md-0 mt-3">
            <p>Assigned To</p>
            <div className="team_members_data_ui_div">
              <UserParticipants2 data={taskData?.TaskMembers || []} />
            </div>
          </li>
        </ul>
        <ul className="task_right_drawer_ui_body_created2">
          <li>
            <p>Start Date</p>
            <span>{moment(taskData?.start_date).format('DD MMM')}</span>
          </li>
          <li className=" ms-md-5 ms-0 mt-md-0 mt-3">
            <p>Due Date</p>
            <span>{moment(taskData?.end_date).format('DD MMM')}</span>
          </li>
          <li className=" ms-md-5 ms-0 mt-md-0 mt-3">
            <p>Progress</p>
            <span>
              <b>
                {taskData?.isCompleted ? 100 : getTaskProgress()}% Completed
              </b>
            </span>
          </li>
          <li className=" ms-md-5 ms-0 mt-md-0 mt-3">
            <p>Priority</p>
            <p
              style={{backgroundColor: taskData?.Priority?.bg_color}}
              className="task_right_drawer_ui_body_Priority ">
              <span style={{color: taskData?.Priority?.text_color}}>
                {taskData?.Priority?.name}
              </span>
            </p>
          </li>
          <li className=" ms-md-5 ms-0 mt-md-0 mt-3">
            <p>Delay</p>
            <span style={{color: 'red'}}>
              <b>{delay && !taskData?.isCompleted ? `${delay} days` : '- -'}</b>
            </span>
          </li>
          <li className=" ms-md-5 ms-0 mt-md-0 mt-3">
            <p style={{marginBottom: '8px'}}>Approval</p>
            <span
              className="new_sub_task_list_badge2"
              style={{
                backgroundColor: '#D9E8D3',
                color: '#FF0000',
              }}>
              approved
            </span>
          </li>
        </ul>
        <div className="profile_switch_top">
          <div className="task_right_drawer_ui_body_Dependency">
            <p>Dependency</p>
            <div>
              <label className="switch">
                <input
                  type="checkbox"
                  className="switch_input"
                  readOnly
                  checked={Boolean(taskData?.dependency)}
                  onChange={e => handleTaskDependencyStatus(e)}></input>
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </div>
        {taskData?.dependency ? (
          <div className="task_right_drawer_ui_body_Dependency_show">
            <FullBtn
              name="Create Dependent Task"
              link="/Management/create_task"
            />
            <div className="task_right_drawer_ui_body_Dependency_show_text">
              <div className="task_right_drawer_ui_body_Dependency_select">
                <p>Blocked By</p>
                <select
                  onChange={e =>
                    onTaskBlockageChange({blockedByTask: e.target.value})
                  }
                  class="form-select">
                  <option value={0}>Select Task</option>
                  {taskList?.map(task => (
                    <option
                      selected={taskData?.blockedByTask == task.id}
                      value={task.id}>
                      {task.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="task_right_drawer_ui_body_Dependency_select">
                <p>Blocking</p>
                <select
                  onChange={e =>
                    onTaskBlockageChange({blockingTask: e.target.value})
                  }
                  class="form-select">
                  <option value={0}>Select Task</option>
                  {taskList?.map(task => (
                    <option
                      selected={taskData?.blockingTask == task.id}
                      value={task.id}>
                      {task.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        ) : null}
        {blockingTask.length ? (
          <div className="task_right_drawer_ui_body_Dependency_select">
            <p>Blocked By Tasks:</p>
            {blockingTask
              ?.map(task => <i>{task.name}</i>)
              .reduce((prev, curr) => [prev, ', ', curr])}
          </div>
        ) : null}
        <hr />
        <div className="task_right_drawer_ui_body_description">
          <h3>
            <TbFileDescription /> Description{' '}
            {editDesc ? null : (
              <EditOutlinedIcon
                style={{marginLeft: 10, cursor: 'pointer'}}
                onClick={handleEditDesc}
              />
            )}
          </h3>
          {editDesc ? (
            <div className="task_desc">
              <div className="task_right_drawer_ui_body_description_text">
                <Editor
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  placeholder="Describe the details of the task/ Write about the task in brief"
                  editorState={newTaskDesc}
                  onEditorStateChange={e => setNewTaskDesc(e)}
                />
              </div>
              <Button
                onClick={handleUpdateDesc}
                style={{marginTop: 8}}
                variant="outlined">
                Save
              </Button>
              <Button
                onClick={handleCancelUpdateDesc}
                style={{marginTop: 8, marginLeft: 6}}>
                Cancel
              </Button>
            </div>
          ) : (
            ReactHtmlParser(taskData?.desc)
          )}
          <hr />
        </div>

        {taskData?.TaskAttachments?.length ? (
          <div className="task_right_drawer_ui_body_description">
            <h3>
              <ImAttachment /> Attachments
            </h3>
            <div className="row g-2">
              {taskData?.TaskAttachments?.map((val, i) => (
                <DocumentsAttachments
                  cols="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6"
                  key={i}
                  original_name={val.original_name}
                  url={getFileIcon(val, false)}
                  handleDelete={() => handleDeleteAttachment(i, val)}
                />
              ))}
            </div>
            <hr />
          </div>
        ) : null}

        <TaskTabs />
      </div>
    </div>
  );
};
const getFileIcon = (file, crateImageObject = true) => {
  switch (file.type) {
    case 'image/jpeg':
      return crateImageObject ? URL.createObjectURL(file) : file.url;
    case 'image/png':
      return crateImageObject ? URL.createObjectURL(file) : file.url;
    case 'application/pdf':
      return CommentsPdfIcon;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return CommentsPdfIcon2;
    case 'application/ppx':
      return CommentsPdfIcon3;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return CommentsPdfIcon4;
    case 'application/zip':
      return CommentsPdfIcon5;
    default:
      return CommentsPdfIcon5;
  }
};
const handleDeleteTask = taskId => {
  Block.circle('.delete_modal');
  Axios.delete('/maxproject/task/internal_task/' + taskId)
    .then(({data}) => {
      Block.remove('.delete_modal');
      Notify.success(data.message);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    })
    .catch(err => {
      //removing loader
      Block.remove('.delete_modal');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
    });
};
