import moment from 'moment';

export const getMeetingTime = ({date, start_time, isAllDay}) =>
  Number(isAllDay)
    ? moment(date, 'YYYY-MM-DD').calendar(moment(), {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'YYYY-MM-DD',
      })
    : // Combine date and time if it's not an all day event
      moment(`${date}T${start_time}`).calendar();
// Function to format integer as time duration
export const formatDuration = (durationInSeconds, shortTime = false) => {
  const duration = moment.duration(durationInSeconds, 'seconds');
  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours()) % 24;
  const minutes = Math.floor(duration.asMinutes()) % 60;
  const seconds = duration.seconds();

  // Build an array of non-zero components
  const components = [];
  //if shortTime is true then only show small time units
  if (days > 0) components.push(`${days} d${days > 1 ? 's' : ''}`);
  if (hours > 0)
    components.push(
      `${hours}${shortTime ? `h` : ` hr${hours > 1 ? 's' : ''}`}`,
    );
  if (minutes > 0)
    components.push(
      `${minutes}${shortTime ? `m` : ` min${minutes > 1 ? 's' : ''}`}`,
    );
  if (seconds > 0)
    components.push(
      `${seconds}${shortTime ? `s` : ` sec${minutes > 1 ? 's' : ''}`}`,
    );

  // Format the duration with labels
  const formattedDuration = components.join(`${shortTime ? ' ' : ', '}`);
  return formattedDuration;
};
export const calDuration = timer => {
  if (!timer) return 0;
  //calculating duration
  let duration = timer.status
    ? moment(new Date()).diff(timer.updatedAt, 'seconds') + timer.duration
    : timer.duration;
  //returning formatted duration
  return formatDuration(duration, true);
};
