//STYLES
import styles from './Navbar.module.scss';

//CONTEXT
import {useContext} from 'react';
import NavContext from '../../Context/NavContext';

//REACT ROUTER
import {NavLink} from 'react-router-dom';
import Logo from '../../../../pics/Management/max_pm.png';
import LogoImg from '../../../common/components/NavLogo/Logo';
import {FiChevronDown, FiChevronRight} from 'react-icons/fi';
import {AiOutlineSearch} from 'react-icons/ai';
import Input from '../../../common/components/input/Input';
import {useState} from 'react';
import ClientsIcon from '../../../../pics/Management/clientsIcon.png';
import Settings from '../../../../pics/Management/Settings.png';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const NavUrl = ({
  url,
  icon,
  description,
  navItems,
  pointerEvents,
  disabled = false,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const {nav, setNav} = useContext(NavContext);
  const checkWindowSize = () => {
    if (window.innerWidth < 1024) setNav(!nav);
  };

  const DropdownItem = () => (
    <>
      <div
        className={styles.test}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        {icon}
        <span className={styles.description}>
          {description}
          <span className="last_icon">
            {navItems ? (
              isDropdownOpen ? (
                <FiChevronRight />
              ) : (
                <FiChevronDown />
              )
            ) : null}
          </span>
        </span>
      </div>
      <div
        className={`${
          isDropdownOpen ? 'isDropdownOpen_show' : 'isDropdownOpen_hide'
        }`}>
        {navItems.map((item, i) => (
          <div className="isDropdownOpen_sub_menu" key={i}>
            <NavLink
              to={item.link}
              onClick={() => checkWindowSize()}
              className={({isActive}) =>
                isActive ? styles.active : undefined
              }>
              <span>
                {item.iconIner}
                {item.title}
              </span>
            </NavLink>
          </div>
        ))}
      </div>
    </>
  );
  const NavItem = () => (
    <div style={disabled ? {cursor: 'not-allowed'} : null}>
      <NavLink
        style={{
          pointerEvents,
          ...(disabled ? {color: 'gray', filter: 'opacity(0.5)'} : {}),
        }}
        to={url}
        className={({isActive}) => (isActive ? styles.active : undefined)}
        onClick={() => checkWindowSize()}>
        {icon}
        <span className={styles.description}>{description}</span>
      </NavLink>
    </div>
  );
  return (
    <li className={styles.li_navlink}>
      {navItems ? <DropdownItem /> : <NavItem />}
    </li>
  );
};
const NavImg = props => {
  return (
    <>
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip>{props.toolTipTitle}</Tooltip>}>
        <img className="nav_img" src={props.img} alt="icon" />
      </OverlayTrigger>
    </>
  );
};

const Navbar = () => {
  const {nav, setNav} = useContext(NavContext);

  return (
    <div
      className={`${styles.navbar_container} ${
        nav ? styles.navbar_mobile_active : undefined
      }`}>
      <nav className={nav ? undefined : styles.nav_small}>
        {/* LOGO */}
        <LogoImg className={styles.logo_div} img={Logo} alt="logo" />

        {/* MENU */}
        <div className="menu_container_new">
          <ul className={styles.menu_container}>
            <div className=" d-block d-xxl-none d-xl-none d-md-none">
              <div className="nav_mobile_search_main_div">
                <div className="nav_mobile_search_iner_div">
                  <span>
                    <AiOutlineSearch />
                  </span>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
              </div>
            </div>

            <NavUrl
              url="/Management/clients"
              icon={<NavImg img={ClientsIcon} toolTipTitle="Clients" />}
              description="Clients"
            />

            <NavUrl
              url="/Management/settings"
              icon={<NavImg img={Settings} toolTipTitle="Settings" />}
              description="Settings"
            />
          </ul>
        </div>
      </nav>

      <div
        className={nav ? styles.mobile_nav_background_active : undefined}
        onClick={() => {
          setNav(!nav);
        }}></div>
    </div>
  );
};

export default Navbar;
