import {PageAllTopTitle} from '../../../../../common/ManagementComponents/PageAllTopTitle';
import {BsArrowLeft} from 'react-icons/bs';

import {
  InputBox,
  SelectBox,
} from '../../../../../common/ManagementComponents/form/Form';
import Select from 'react-select';
import {useState, useRef} from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Editor} from 'react-draft-wysiwyg';
import {
  OutlineBtn,
  FullBtn,
} from '../../../../../common/ManagementComponents/Btn/Btn';
import * as React from 'react';
import {useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Block} from 'notiflix/build/notiflix-block-aio';
import Axios from './../../../../../../config/api';
import {StatusCode} from '../../../../../../constants';
import draftToHtml from 'draftjs-to-html';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import {Confirm} from 'notiflix/build/notiflix-confirm-aio';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import {AiOutlinePlus} from 'react-icons/ai';
import {IoMdCloseCircleOutline} from 'react-icons/io';
import Avatar from '@mui/material/Avatar';
import Modal from 'react-bootstrap/Modal';
export const CreateTask = ({type, isUpdate}) => {
  const [taskType, setTaskType] = useState(null);
  const options = [
    {
      value: 'internal',
      label: 'Internal Employee',
    },
    {
      value: 'client',
      label: 'Client',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ];

  useEffect(() => {
    if (type == 'client') setTaskType(options[1]);
    else if (type == 'other') setTaskType(options[2]);
    else setTaskType(options[0]);
    return () => {};
  }, []);
  return (
    <div className="custom_container">
      <PageAllTopTitle link={-1} icon={<BsArrowLeft />} title="back" />
      <div className="custom_container_iner">
        <div className="CompanySetup_main_div">
          <div className="CompanySetup_inner_main_div">
            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
              <div className="col-12">
                <div className="create_task_title">
                  <h3>{isUpdate ? 'Update' : 'Create'} task</h3>
                </div>
              </div>
              <div className="col-12">
                <label className="all_page_my_label_new">
                  {isUpdate ? 'Updating' : 'Create'} Task For*
                </label>
                <div className="dashboard_top_week_Select drop_box">
                  <Select
                    isDisabled={isUpdate}
                    options={options}
                    value={taskType}
                    isSearchable={false}
                    onChange={value => setTaskType(value)}
                  />
                </div>
              </div>
              <div className="col-12">
                {
                  {
                    internal: <InternalEmployeeTask />,
                    client: <ClientTask />,
                    other: <OtherTask />,
                  }[taskType?.value]
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const InternalEmployeeTask = () => {
  const [visible, setVisible] = useState(true);
  const {state} = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [approvalModal, setApprovalModal] = React.useState(false);
  // Access the meeting_note_id from the state object
  const meetingNote = state?.meetingNote;
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
    setValue,
    getValues,
  } = useForm();
  const [task, setTask] = useState({});
  const [serverErrors, setServerErrors] = useState({});
  const [service, setService] = useState({});
  const [servicesData, setServicesData] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [selectValues, setSelectValues] = useState({
    priorities: [],
    projects: [],
    frequencies: [],
    departments: [],
    services: [],
    milestones: [],
    users: [],
    statuses: [],
  });
  const [requireSwitch, setShowRequireSwitch] = useState(false);

  useEffect(async () => {
    fetchTaskSelectFieldsData();
    if (params?.task_id) fetchTask();

    return () => {};
  }, []);

  const onProjectChange = project_id => {
    setValue('department', null);
    setValue('service', null);
    setValue('milestone', null);
    setValue('task_users', null);
    setSelectValues(old => ({
      ...old,
      departments: [],
      services: [],
      milestones: [],
      users: [],
    }));
    fetchDepartmentsByProjectId(project_id);
    fetchTeamByProjectId(project_id);
  };
  const onDepartmentChange = department_id => {
    setValue('service', null);
    setValue('milestone', null);
    setSelectValues(old => ({
      ...old,
      services: [],
      milestones: [],
    }));
    fetchProjectServicesByDepartmentId(department_id);
  };
  const onServiceChange = project_service_id => {
    setValue('milestone', null);
    setSelectValues(old => ({
      ...old,
      milestones: [],
    }));
    fetchProjectMilestonesByServiceId(project_service_id);
    const Service = servicesData.find(service => service?.project_service_id);
    setService(Service);
    setValue('start_date', null);
    setValue('end_date', null);
  };
  const fetchTask = () => {
    //showing up loader
    Block.circle('#task_form', 'Loading...');
    Axios.fetch(`maxproject/task/internal_task/${params.task_id}`)
      .then(({data}) => {
        let payload = {
          task_name: data.name,
          start_date: data.start_date,
          end_date: data.end_date,
          description: getDraftFromHtml(data.desc),
          priority: {
            label: data?.Priority?.name,
            value: data?.Priority?.id,
          },
          project: {
            label: data?.Project?.name,
            value: data?.Project?.id,
          },
          frequency: {
            label: data?.Frequency?.name,
            value: data?.Frequency?.id,
          },
          status: {
            label: data?.Status?.name,
            value: data?.Status?.id,
          },
          milestone: {
            label: data?.Milestone?.title,
            value: data?.Milestone?.id,
          },
          department: {
            label: data?.Department?.name,
            value: data?.Department?.id,
          },
          service: {
            label: data?.ProjectService?.Service?.name,
            value: data?.ProjectService?.project_service_id,
          },
          task_users: data?.TaskMembers?.map(user => ({
            label: user?.User?.name,
            value: user?.user_id,
          })),
        };
        //getting task owner
        let TaskOwner = data?.TaskMembers.find(member => member.isOwner);
        //if is there an task owner then set it
        if (TaskOwner)
          payload.task_owner = {
            label: TaskOwner?.User?.name,
            value: TaskOwner?.user_id,
          };
        setTask(payload);
        setSelectedAssignees(payload.task_users);
        reset(payload);
        Block.remove('#task_form');
      })
      .catch(err => {
        //removing loader
        Block.remove('#task_form');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchTaskSelectFieldsData = () => {
    Axios.fetch(
      `maxproject/random/task_select_fields?task_id=${params?.task_id}`,
    )
      .then(({data}) => {
        let projects = data?.projects?.map(el => ({
          value: el?.id,
          label: el?.name,
        }));
        let priorities = data?.priorities?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let statuses = data?.statuses?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let frequencies = data?.frequencies?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let departments = data.departments.map(d => ({
          label: d.Department.name,
          value: d.department_id,
        }));
        let services = data.services.map(d => ({
          label: d?.Service?.name,
          value: d?.project_service_id,
        }));
        let milestones = data.milestones.map(d => ({
          label: d?.title,
          value: d?.id,
        }));
        let users = data.users.map(u => ({
          label: u.name,
          value: u.id,
        }));

        setSelectValues(old => ({
          ...old,
          projects,
          priorities,
          frequencies,
          departments,
          services,
          milestones,
          users,
          statuses,
        }));
        if (meetingNote) {
          let payload = {};
          if (meetingNote.projectId)
            payload.project = projects.find(
              p => p.value == meetingNote.projectId,
            );
          payload.description = getDraftFromHtml(
            `<p>${meetingNote.noteTitle}</p>`,
          );
          setTask(payload);
          reset(payload);
        }
      })
      .catch(err => {
        console.log(err);
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchDepartmentsByProjectId = project_id => {
    const URL = `maxproject/project_service/departments/${project_id}`;
    Axios.fetch(URL)
      .then(({data}) => {
        let departments = data.departments.map(d => ({
          label: d.Department.name,
          value: d.department_id,
        }));
        setSelectValues(old => ({...old, departments}));
        Block.remove('#task_form');
      })
      .catch(err => {
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchTeamByProjectId = project_id => {
    const URL = `maxproject/team/members/${project_id}`;
    Axios.fetch(URL)
      .then(({data}) => {
        let users = data.members.map(u => ({
          label: u.name,
          value: u.id,
        }));
        setSelectValues(old => ({...old, users}));
      })
      .catch(err => {
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchProjectServicesByDepartmentId = department_id => {
    let project = getValues('project');
    const URL = `maxproject/project_service/services_by_department/${department_id}/${project?.value}`;
    Axios.fetch(URL)
      .then(({data}) => {
        let services = data.services.map(d => ({
          label: d?.Service?.name,
          value: d?.project_service_id,
        }));
        setServicesData(data.services);
        setSelectValues(old => ({...old, services}));
      })
      .catch(err => {
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchProjectMilestonesByServiceId = project_service_id => {
    let project = getValues('project');
    const URL = `maxproject/project_milestone/by_service/${project_service_id}/${project?.value}`;
    Axios.fetch(URL)
      .then(({data}) => {
        let milestones = data.milestones.map(d => ({
          label: d?.title,
          value: d?.id,
        }));
        setSelectValues(old => ({...old, milestones}));
      })
      .catch(err => {
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = (data, e, force = false) => {
    let payload = {
      task_name: data.task_name,
      project_id: data.project.value,
      start_date: data.start_date,
      end_date: data.end_date,
      priority_id: data.priority.value,
      frequency_id: data.frequency.value,
      status_id: data.status.value,
      milestone_id: data.milestone.value,
      department_id: data.department.value,
      task_owner: data.task_owner?.value,
      service_id: data.service.value,
      task_users: data.task_users?.map(u => u.value),
      desc: task.description
        ? draftToHtml(convertToRaw(task?.description?.getCurrentContent()))
        : '',
    };
    //clearing server errors
    setServerErrors({});
    //showing up loader
    Block.circle('#task_form', 'Saving...');
    //initiating network request
    const Request = params?.task_id
      ? Axios.put(
          `maxproject/task/internal_task/${params.task_id}${
            force ? '?force=1' : ''
          }`,
          payload,
        )
      : Axios.post('maxproject/task/internal_task', payload);

    Request.then(res => {
      Block.remove('#task_form');
      Notify.success(res.data.message);
      if (res.data.status == 1) navigate('/Management/tasks');
      else if (res.data.status == -1)
        Confirm.show(res.data.message, 'Still Proceed!!', 'Yes', 'No', () =>
          handleFormSubmit(data, null, true),
        );
      else if (res.data.status == -2) Notify.failure(res.data.message);
    }).catch(err => {
      //removing loader
      Block.remove('#task_form');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
      //checking and showing express validation errors
      if (err?.response?.status == StatusCode.ExpressErrors)
        err?.response?.data?.errors.forEach(el =>
          setServerErrors(old => ({...old, [el.param]: el.msg})),
        );
    });
  };
  const onAssigneeChange = assignees => {
    setSelectedAssignees(assignees);
    let found = assignees.find(a => a.value == getValues('task_owner')?.value);
    if (!found) setValue('task_owner', null);
  };
  const AntSwitch = styled(Switch)(({theme}) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255,255,255,.35)'
          : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));
  const SwitchHandleChange = () => {
    setShowRequireSwitch(!requireSwitch);
  };
  const removeElement = () => {
    setVisible(prev => !prev);
  };
  const ApprovalModal = props => {
    const UserUi = props => {
      return (
        <div className="assign_to_modal_ui">
          <Avatar
            alt={props.userName}
            src={props.userImg}
            sx={{width: 24, height: 24}}
          />
          <span>{props.userName}</span>
        </div>
      );
    };
    const UsersNew = [
      {
        value: 'sunny charkhwal',
        label: (
          <UserUi
            userName="sunny charkhwal"
            userImg="https://mui.com/static/images/avatar/2.jpg"
          />
        ),
      },
      {
        value: 'kamal',
        label: (
          <UserUi
            userName="kamal "
            userImg="https://mui.com/static/images/avatar/3.jpg"
          />
        ),
      },
      {
        value: 'sunny',
        label: (
          <UserUi
            userName="sunny"
            userImg="https://mui.com/static/images/avatar/4.jpg"
          />
        ),
      },
    ];
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="approval_modal_title">
            Task title goes here...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="approval_modal_sub_title_div">
            <ul>
              <li className="li_first">Project</li>
              <li>: Project management tool</li>
            </ul>
            <ul>
              <li className="li_first">Priority</li>
              <li>
                :
                <span style={{backgroundColor: '#F3D32C', color: 'white'}}>
                  low
                </span>
              </li>
            </ul>
            <ul>
              <li className="li_first">Start date</li>
              <li>: 12-Sep-2023</li>
            </ul>
            <ul>
              <li className="li_first">End date</li>
              <li>: 19-Oct-2023</li>
            </ul>
          </div>
          <div className="new_sub_task_main_div approval_new_sub_task_main_div">
            <div className="new_sub_task_top_div">
              <div className="row g-xxl-3 g-xl-3 g-lg-3 g-md-3 g-sm-3 g-3">
                {/* list title */}
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <div className="approval_modal_sub_task_title">
                        Subtask
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="approval_modal_sub_task_date_title d-flex justify-content-center">
                        Start date
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="approval_modal_sub_task_date_title">
                        End date
                      </div>
                    </div>
                  </div>
                </div>
                {/* list title end */}
                {/* list title item */}
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <div className="approval_modal_sub_list_task_title">
                        Define the off page activity in clear words and
                        communicate with.
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="approval_modal_sub_task_list_date_title d-flex justify-content-center">
                        01/01/2024
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="approval_modal_sub_task_list_date_title">
                        01/01/2024
                      </div>
                    </div>
                  </div>
                </div>
                {/* list title item end*/}
                {/* list title item */}
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <div className="approval_modal_sub_list_task_title">
                        Lorem ipsum dolor sit amet consectetur.
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="approval_modal_sub_task_list_date_title d-flex justify-content-center">
                        01/01/2024
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="approval_modal_sub_task_list_date_title">
                        01/01/2024
                      </div>
                    </div>
                  </div>
                </div>
                {/* list title item end*/}
                {/* list title item */}
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <div className="approval_modal_sub_list_task_title">
                        Define the off page activity in clear words and
                        communicate with content team.
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="approval_modal_sub_task_list_date_title d-flex justify-content-center">
                        01/01/2024
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="approval_modal_sub_task_list_date_title">
                        01/01/2024
                      </div>
                    </div>
                  </div>
                </div>
                {/* list title item end*/}
                {/* list title item */}
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <div className="approval_modal_sub_list_task_title">
                        Define the off page activity in clear words and
                        communicate with content team.
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="approval_modal_sub_task_list_date_title d-flex justify-content-center">
                        01/01/2024
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="approval_modal_sub_task_list_date_title">
                        01/01/2024
                      </div>
                    </div>
                  </div>
                </div>
                {/* list title item end*/}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <div className="approval_modal_user_div">
                <label>Select approver</label>
                <Select
                  isMulti
                  // menuIsOpen
                  options={UsersNew}
                  placeholder="Select user"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3 mt-5">
              <div className=" d-flex align-items-center justify-content-end">
                <div className="me-2">
                  <OutlineBtn onClick={props.onHide} name="Cancel" />
                </div>
                <div className=" ms-2">
                  <button className="management_btn_full2">send</button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <form id="task_form" onSubmit={handleSubmit(handleFormSubmit)}>
      <ApprovalModal
        show={approvalModal}
        onHide={() => setApprovalModal(false)}
      />
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            type="text"
            label="Task Name*"
            placeholder="Enter task name"
            register={() =>
              register('task_name', {
                required: 'Please enter task name',
                value: task?.task_name,
              })
            }
            errorMessage={errors.task_name?.message || serverErrors?.task_name}
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Project*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="project"
              control={control}
              rules={{
                required: 'Please select a project',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  isDisabled={params?.task_id}
                  defaultValue={task?.project}
                  onChange={el => {
                    field.onChange(el);
                    onProjectChange(el.value);
                  }}
                  options={selectValues.projects}
                  placeholder={field.value || 'Select Project'}
                />
              )}
            />
            <small className="error_message">
              {errors.project?.message || serverErrors?.project}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Department*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="department"
              control={control}
              rules={{
                required: 'Please select a department',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.department}
                  onChange={el => {
                    field.onChange(el);
                    onDepartmentChange(el.value);
                  }}
                  options={selectValues.departments}
                  placeholder={field.value || 'Select Department*'}
                />
              )}
            />
            <small className="error_message">
              {errors.department?.message || serverErrors?.department}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Services*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="service"
              control={control}
              rules={{
                required: 'Please select a service',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.service}
                  onChange={el => {
                    field.onChange(el);
                    onServiceChange(el.value);
                  }}
                  options={selectValues.services}
                  placeholder={field.value || 'Select Service'}
                />
              )}
            />
            <small className="error_message">
              {errors.service?.message || serverErrors?.service}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Milestone*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="milestone"
              control={control}
              rules={{
                required: 'Please select a milestone',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.milestone}
                  onChange={el => field.onChange(el)}
                  options={selectValues.milestones}
                  placeholder={field.value || 'Select Milestone'}
                />
              )}
            />
            <small className="error_message">
              {errors.milestone?.message || serverErrors?.milestone}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Assign To*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="task_users"
              control={control}
              rules={{
                required: 'Please select a user',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  isMulti
                  defaultValue={task?.task_users}
                  onChange={el => {
                    field.onChange(el);
                    onAssigneeChange(el);
                  }}
                  options={selectValues.users}
                  placeholder={field.value || 'Select Team Members'}
                />
              )}
            />
            <small className="error_message">
              {errors.task_users?.message || serverErrors?.task_users}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Owner</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="task_owner"
              control={control}
              rules={{
                required: false,
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.task_owner}
                  onChange={el => field.onChange(el)}
                  options={selectedAssignees}
                  placeholder={field.value || 'Select Task Owner'}
                />
              )}
            />
            <small className="error_message">
              {errors.task_owner?.message || serverErrors?.task_owner}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            defaultValue={task?.start_date}
            register={() =>
              register('start_date', {
                required: 'Please enter start date',
                value: task?.start_date,
                // validate: {
                //   isBetween
                // },
              })
            }
            errorMessage={
              errors.start_date?.message || serverErrors?.start_date
            }
            min={service?.start_date}
            max={service?.end_date}
            type="date"
            label="Start Date*"
            placeholder=""
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            defaultValue={task?.end_date}
            register={() =>
              register('end_date', {
                required: 'Please enter onboarding date',
                value: task?.end_date,
              })
            }
            errorMessage={errors.end_date?.message || serverErrors?.end_date}
            type="date"
            min={service?.start_date}
            max={service?.end_date}
            label="End Date*"
            placeholder=""
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Priority*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="priority"
              control={control}
              rules={{
                required: 'Please select a priority',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.priority}
                  onChange={el => field.onChange(el)}
                  options={selectValues.priorities}
                  placeholder={field.value || 'Select Priority'}
                />
              )}
            />
            <small className="error_message">
              {errors.priority?.message || serverErrors?.priority}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Frequency*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="frequency"
              control={control}
              rules={{
                required: 'Please select a frequency',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.frequency}
                  onChange={el => field.onChange(el)}
                  options={selectValues.frequencies}
                  placeholder={field.value || 'Select Frequency'}
                />
              )}
            />
            <small className="error_message">
              {errors.frequency?.message || serverErrors?.frequency}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Status*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="status"
              control={control}
              rules={{
                required: 'Please select a task status',
              }}
              render={({field}) => (
                <Select
                  // menuIsOpen={true}
                  {...field}
                  defaultValue={task?.status}
                  onChange={el => field.onChange(el)}
                  options={selectValues.statuses}
                  placeholder={field.value || 'Select Status'}
                />
              )}
            />
            <small className="error_message">
              {errors.status?.message || serverErrors?.status}
            </small>
          </div>
        </div>
        <div className="col-12">
          <FormGroup>
            <Stack direction="row" spacing={1} alignItems="center">
              <AntSwitch
                inputProps={{'aria-label': 'ant design'}}
                checked={requireSwitch}
                onChange={SwitchHandleChange}
              />
              <Typography className="approval_request_input_switch_text">
                Do you want to send this task for approval?
              </Typography>
            </Stack>
          </FormGroup>
        </div>
        {requireSwitch ? (
          <div className="col-12">
            <div className="row mb-5 g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <InputBox
                  type="text"
                  label="Add subtasks"
                  placeholder="Enter sub task name"
                />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <InputBox
                  type="date"
                  label="Start Date*"
                  errorMessage="Please enter start date"
                />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <InputBox
                  type="date"
                  label="end Date*"
                  errorMessage="end date is required"
                />
              </div>
              <div className="col-12">
                <button className="text_button">
                  <AiOutlinePlus />
                  Add Subtask
                </button>
              </div>
            </div>
            {visible && (
              <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 add_sub_task_date_new_div_top">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 add_sub_task_date_new_div">
                  <span className="add_sub_task_date_new_icon_div">
                    <IoMdCloseCircleOutline
                      onClick={() => {
                        Confirm.show(
                          `Delete?`,
                          `This action is undonable!`,
                          'Cancel',
                          'Delete',
                          () => {},
                          () => {
                            removeElement();
                          },
                        );
                      }}
                    />
                  </span>
                  <SubTaskEdit />
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 ">
                  <div className="add_sub_task_date_new">
                    <InputBox type="date" label="Start Date*" />
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 ">
                  <div className="add_sub_task_date_new">
                    <InputBox type="date" label="Start Date*" />
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
        <div className="col-12">
          <label className="all_page_my_label_new">Description</label>
          <div className="Hire_editer">
            <Editor
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              placeholder="Describe the details of the task/ Write about the task in brief"
              editorState={task?.description}
              onEditorStateChange={e =>
                setTask(old => ({...old, description: e}))
              }
            />
          </div>
        </div>
        <div className="col-12 mb-3 mt-5">
          <div className=" d-flex align-items-center justify-content-center">
            <div className="me-2">
              <OutlineBtn onClick={() => navigate(-1)} name="Cancel" />
            </div>
            <div className=" ms-2">
              {requireSwitch ? (
                <button
                  className="management_btn_full2"
                  onClick={() => setApprovalModal(true)}>
                  send for approvals
                </button>
              ) : (
                <button
                  className="management_btn_full2"
                  type="submit"
                  form={'task_form'}>
                  {params?.task_id ? 'Update' : 'Save'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
const OtherTask = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm();

  const [task, setTask] = useState({});
  const [serverErrors, setServerErrors] = useState({});
  const [selectValues, setSelectValues] = useState({
    priorities: [],
    frequencies: [],
    users: [],
    statuses: [],
  });

  useEffect(async () => {
    fetchTaskSelectFieldsData();
    fetchTeamMembers();
    if (params?.task_id) fetchTask();

    return () => {};
  }, []);

  const fetchTask = () => {
    //showing up loader
    Block.circle('#task_form', 'Loading...');
    Axios.fetch(`maxproject/task/internal_task/${params.task_id}`)
      .then(({data}) => {
        let payload = {
          task_name: data.name,
          start_date: data.start_date,
          end_date: data.end_date,
          description: getDraftFromHtml(data.desc),
          priority: {
            label: data?.Priority?.name,
            value: data?.Priority?.id,
          },
          frequency: {
            label: data?.Frequency?.name,
            value: data?.Frequency?.id,
          },
          status: {
            label: data?.Status?.name,
            value: data?.Status?.id,
          },
          task_users: data?.TaskMembers?.map(user => ({
            label: user?.User?.name,
            value: user?.user_id,
          })),
        };
        setTask(payload);
        reset(payload);
        Block.remove('#task_form');
      })
      .catch(err => {
        //removing loader
        Block.remove('#task_form');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchTaskSelectFieldsData = () => {
    Axios.fetch(
      `maxproject/random/task_select_fields?task_id=${params?.task_id}`,
    )
      .then(({data}) => {
        let priorities = data?.priorities?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let statuses = data?.statuses?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let frequencies = data?.frequencies?.map(el => ({
          value: el.id,
          label: el.name,
        }));

        setSelectValues(old => ({
          ...old,
          priorities,
          frequencies,
          statuses,
        }));
      })
      .catch(err => {
        console.log(err);
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchTeamMembers = () => {
    const URL = `maxproject/team/members`;
    Axios.fetch(URL)
      .then(({data}) => {
        let users = data.members.map(u => ({
          label: u.name,
          value: u.id,
        }));
        setSelectValues(old => ({...old, users}));
      })
      .catch(err => {
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = (data, e, force = false) => {
    let payload = {
      task_name: data.task_name,
      start_date: data.start_date,
      end_date: data.end_date,
      priority_id: data.priority.value,
      frequency_id: data.frequency.value,
      status_id: data.status.value,
      task_users: data.task_users?.map(u => u.value),
      desc: task.description
        ? draftToHtml(convertToRaw(task?.description?.getCurrentContent()))
        : '',
    };
    //clearing server errors
    setServerErrors({});
    //showing up loader
    Block.circle('#task_form', 'Saving...');
    //initiating network request
    const Request = params?.task_id
      ? Axios.put(
          `maxproject/task/other_task/${params.task_id}${
            force ? '?force=1' : ''
          }`,
          payload,
        )
      : Axios.post('maxproject/task/other_task', payload);

    Request.then(res => {
      Block.remove('#task_form');
      Notify.success(res.data.message);
      if (res.data.status == 1) navigate('/Management/tasks');
      else if (res.data.status == -1)
        Confirm.show(res.data.message, 'Still Proceed!!', 'Yes', 'No', () =>
          handleFormSubmit(data, null, true),
        );
      else if (res.data.status == -2) Notify.failure(res.data.message);
    }).catch(err => {
      //removing loader
      Block.remove('#task_form');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
      //checking and showing express validation errors
      if (err?.response?.status == StatusCode.ExpressErrors)
        err?.response?.data?.errors.forEach(el =>
          setServerErrors(old => ({...old, [el.param]: el.msg})),
        );
    });
  };
  return (
    <form id="task_form" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            type="text"
            label="Task Name*"
            placeholder="Enter task name"
            register={() =>
              register('task_name', {
                required: 'Please enter task name',
                value: task?.task_name,
              })
            }
            errorMessage={errors.task_name?.message || serverErrors?.task_name}
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Assign To*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="task_users"
              control={control}
              rules={{
                required: 'Please select a user',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  isMulti
                  defaultValue={task?.task_users}
                  onChange={el => field.onChange(el)}
                  options={selectValues.users}
                  placeholder={field.value || 'Select Team Members'}
                />
              )}
            />
            <small className="error_message">
              {errors.task_users?.message || serverErrors?.task_users}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            defaultValue={task?.start_date}
            register={() =>
              register('start_date', {
                required: 'Please enter start date',
                value: task?.start_date,
              })
            }
            errorMessage={
              errors.start_date?.message || serverErrors?.start_date
            }
            type="date"
            label="Start Date*"
            placeholder=""
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            defaultValue={task?.end_date}
            register={() =>
              register('end_date', {
                required: 'Please enter onboarding date',
                value: task?.end_date,
              })
            }
            errorMessage={errors.end_date?.message || serverErrors?.end_date}
            type="date"
            label="End Date*"
            placeholder=""
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Priority*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="priority"
              control={control}
              rules={{
                required: 'Please select a priority',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.priority}
                  onChange={el => field.onChange(el)}
                  options={selectValues.priorities}
                  placeholder={field.value || 'Select Priority'}
                />
              )}
            />
            <small className="error_message">
              {errors.priority?.message || serverErrors?.priority}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Frequency*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="frequency"
              control={control}
              rules={{
                required: 'Please select a frequency',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.frequency}
                  onChange={el => field.onChange(el)}
                  options={selectValues.frequencies}
                  placeholder={field.value || 'Select Frequency'}
                />
              )}
            />
            <small className="error_message">
              {errors.frequency?.message || serverErrors?.frequency}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Status*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="status"
              control={control}
              rules={{
                required: 'Please select a task status',
              }}
              render={({field}) => (
                <Select
                  // menuIsOpen={true}
                  {...field}
                  defaultValue={task?.status}
                  onChange={el => field.onChange(el)}
                  options={selectValues.statuses}
                  placeholder={field.value || 'Select Status'}
                />
              )}
            />
            <small className="error_message">
              {errors.status?.message || serverErrors?.status}
            </small>
          </div>
        </div>
        <div className="col-12">
          <label className="all_page_my_label_new">Description</label>
          <div className="Hire_editer">
            <Editor
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              placeholder="Describe the details of the task/ Write about the task in brief"
              editorState={task?.description}
              onEditorStateChange={e =>
                setTask(old => ({...old, description: e}))
              }
            />
          </div>
        </div>
        <div className="col-12 mb-3 mt-5">
          <div className=" d-flex align-items-center justify-content-center">
            <div className="me-2">
              <OutlineBtn onClick={() => navigate(-1)} name="Cancel" />
            </div>
            <div className=" ms-2">
              <button
                className="management_btn_full2"
                type="submit"
                form={'task_form'}>
                {params?.task_id ? 'Update' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
const ClientTask = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    reset,
  } = useForm();

  const [task, setTask] = useState({});
  const [serverErrors, setServerErrors] = useState({});
  const [selectValues, setSelectValues] = useState({
    priorities: [],
    projects: [],
    frequencies: [],
    statuses: [],
  });

  useEffect(async () => {
    fetchTaskSelectFieldsData();
    if (params?.task_id) fetchTask();

    return () => {};
  }, []);

  const fetchTask = () => {
    //showing up loader
    Block.circle('#task_form', 'Loading...');
    Axios.fetch(`maxproject/task/client_task/${params.task_id}`)
      .then(({data}) => {
        let payload = {
          task_name: data.name,
          start_date: data.start_date,
          end_date: data.end_date,
          description: getDraftFromHtml(data.desc),
          priority: {
            label: data?.Priority?.name,
            value: data?.Priority?.id,
          },
          project: {
            label: data?.Project?.name,
            value: data?.Project?.id,
          },
          frequency: {
            label: data?.Frequency?.name,
            value: data?.Frequency?.id,
          },
        };
        setTask(payload);
        reset(payload);
        Block.remove('#task_form');
      })
      .catch(err => {
        //removing loader
        Block.remove('#task_form');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const fetchTaskSelectFieldsData = () => {
    //showing up loader
    Block.circle('#task_form', 'Loading...');
    Axios.fetch(
      `maxproject/random/task_select_fields${
        params?.client_id ? `?client_id=${params?.client_id}` : ``
      }`,
    )
      .then(({data}) => {
        let projects = data?.projects?.map(el => ({
          value: el?.id,
          label: el?.name,
        }));
        let statuses = data?.statuses?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let priorities = data?.priorities?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        let frequencies = data?.frequencies?.map(el => ({
          value: el.id,
          label: el.name,
        }));
        setSelectValues({
          projects,
          priorities,
          frequencies,
          statuses,
        });

        Block.remove('#task_form');
      })
      .catch(err => {
        //removing loader
        Block.remove('#task_form');
        //checking for any error message
        if (err?.response?.data?.message)
          Notify.failure(err?.response?.data?.message);
      });
  };
  const handleFormSubmit = data => {
    let payload = {
      task_name: data.task_name,
      project_id: data.project.value,
      start_date: data.start_date,
      end_date: data.end_date,
      status_id: data.status.value,
      priority_id: data.priority.value,
      frequency_id: data.frequency.value,
      desc: task.description
        ? draftToHtml(convertToRaw(task?.description?.getCurrentContent()))
        : '',
    };
    //clearing server errors
    setServerErrors({});
    //showing up loader
    Block.circle('#task_form', 'Saving...');
    //initiating network request
    const Request = params?.task_id
      ? Axios.put('maxproject/task/client_task/' + params.task_id, payload)
      : Axios.post('maxproject/task/client_task', payload);

    Request.then(({data}) => {
      Block.remove('#task_form');
      Notify.success(data.message);
      navigate('/Management/tasks');
    }).catch(err => {
      //removing loader
      Block.remove('#task_form');
      //checking for any error message
      if (err?.response?.data?.message)
        Notify.failure(err?.response?.data?.message);
      //checking and showing express validation errors
      if (err?.response?.status == StatusCode.ExpressErrors)
        err?.response?.data?.errors.forEach(el =>
          setServerErrors(old => ({...old, [el.param]: el.msg})),
        );
    });
  };
  return (
    <form id="task_form" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            type="text"
            label="Task Name*"
            placeholder="Enter task name"
            register={() =>
              register('task_name', {
                required: 'Please enter task name',
                value: task?.task_name,
              })
            }
            errorMessage={errors.task_name?.message || serverErrors?.task_name}
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Project*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="project"
              control={control}
              rules={{
                required: 'Please select a project',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  isDisabled={params?.task_id}
                  defaultValue={task?.project}
                  onChange={el => field.onChange(el)}
                  options={selectValues.projects}
                  placeholder={field.value || 'Select Project'}
                />
              )}
            />
            <small className="error_message">
              {errors.project?.message || serverErrors?.project}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            defaultValue={task?.start_date}
            register={() =>
              register('start_date', {
                required: 'Please enter start date',
                value: task?.start_date,
              })
            }
            errorMessage={
              errors.start_date?.message || serverErrors?.start_date
            }
            type="date"
            label="Start Date*"
            placeholder=""
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <InputBox
            defaultValue={task?.end_date}
            register={() =>
              register('end_date', {
                required: 'Please enter onboarding date',
                value: task?.end_date,
              })
            }
            errorMessage={errors.end_date?.message || serverErrors?.end_date}
            type="date"
            label="End Date*"
            placeholder=""
          />
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Priority*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="priority"
              control={control}
              rules={{
                required: 'Please select a priority',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.priority}
                  onChange={el => field.onChange(el)}
                  options={selectValues.priorities}
                  placeholder={field.value || 'Select Priority'}
                />
              )}
            />
            <small className="error_message">
              {errors.priority?.message || serverErrors?.priority}
            </small>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Frequency*</label>
          <Controller
            name="frequency"
            control={control}
            rules={{
              required: 'Please select a frequency',
            }}
            render={({field}) => (
              <Select
                {...field}
                defaultValue={task?.frequency}
                onChange={el => field.onChange(el)}
                options={selectValues.frequencies}
                placeholder={field.value || 'Select Frequency'}
              />
            )}
          />
          <small className="error_message">
            {errors.frequency?.message || serverErrors?.frequency}
          </small>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label className="all_page_my_label_new">Status*</label>
          <div className="dashboard_top_week_Select drop_box">
            <Controller
              name="status"
              control={control}
              rules={{
                required: 'Please select a task status',
              }}
              render={({field}) => (
                <Select
                  {...field}
                  defaultValue={task?.status}
                  onChange={el => field.onChange(el)}
                  options={selectValues.statuses}
                  placeholder={field.value || 'Select Status'}
                />
              )}
            />
            <small className="error_message">
              {errors.status?.message || serverErrors?.status}
            </small>
          </div>
        </div>
        <div className="col-12">
          <label className="all_page_my_label_new">Description</label>
          <div className="Hire_editer">
            <Editor
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              placeholder="Describe the details of the task/ Write about the task in brief"
              editorState={task?.description}
              onEditorStateChange={e =>
                setTask(old => ({...old, description: e}))
              }
            />
          </div>
        </div>
        <div className="col-12 mb-3 mt-5">
          <div className=" d-flex align-items-center justify-content-center">
            <div className=" me-2">
              <OutlineBtn onClick={() => navigate(-1)} name="Cancel" />
            </div>
            <div className=" ms-2">
              <button
                className="management_btn_full2"
                type="submit"
                form={'task_form'}>
                {params?.task_id ? 'Update' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export const getDraftFromHtml = html => {
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    return EditorState.createWithContent(contentState);
  }
  return null;
};
const SubTaskEdit = () => {
  const [text, setText] = useState('Define the off page activity in clear.');

  const handleSave = newText => {
    setText(newText);
  };
  const EditableText = ({text, onSave}) => {
    const [isEditing, setEditing] = useState(false);
    const [value, setValue] = useState(text);
    const inputRef = useRef();

    useEffect(() => {
      if (isEditing) {
        inputRef.current.focus();
      }
    }, [isEditing]);

    const handleDoubleClick = () => {
      setEditing(true);
    };

    const handleBlur = () => {
      setEditing(false);
      onSave(value);
    };

    const handleChange = e => {
      setValue(e.target.value);
    };

    return (
      <div>
        {isEditing ? (
          <input
            ref={inputRef}
            type="text"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            className="form-control"
          />
        ) : (
          <div className="sub_task_edit_title">
            <p onClick={handleDoubleClick}>{text}</p>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className=" w-100">
      <EditableText text={text} onSave={handleSave} />
    </div>
  );
};
