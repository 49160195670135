import Accordion from 'react-bootstrap/Accordion';
import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import {BiDotsHorizontalRounded} from 'react-icons/bi';
import {NavLink} from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import {BsFillInfoCircleFill} from 'react-icons/bs';
import {OutlineBtn, FullBtn} from '../Btn/Btn';
import {AiOutlinePlus} from 'react-icons/ai';
import {FullBtnWithIcon} from '../Btn/Btn';
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Block} from 'notiflix/build/notiflix-block-aio';
import Axios from './../../../../config/api';
import {getUser} from '../../../../redux/slices/userSlice';
import AvatarGroup from '@mui/material/AvatarGroup';
import {AiFillCaretDown} from 'react-icons/ai';
import moment from 'moment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SnoozeIcon from '@mui/icons-material/Snooze';
import {Avatar, Pagination, Box, Modal, Fade, Drawer} from '@mui/material';
import Img from '../../../common/components/img/Img';
import NoResultsSVG from '../../../../pics/empty_state_search_not_found.svg';
import {Confirm} from 'notiflix/build/notiflix-confirm-aio';
import {useDispatch, useSelector} from 'react-redux';
import {useNonInitialEffect} from '../../../../utils/hooks/non-initial-effect.tsx';
//
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import {BsFillCircleFill} from 'react-icons/bs';
import {setGlobal} from '../../../../redux/slices/globalSlice';
import {formatDuration, calDuration} from '../../../../utils/functions';
import {useRef} from 'react';
import {useEffect} from 'react';
import TimerIcon from '../../../../pics/Management/timer.svg';
import AprIcon from '../../../../pics/Management/ap.svg';
import {MdAvTimer} from 'react-icons/md';
import {Report} from 'notiflix';

// TasksListView
export const TasksListView = ({
  tasks = [],
  deletedTasks = [],
  setTasks = () => {},
  taskStatusFilters = [],
  taskPriorityFilters = [],
  isProjectTasks = false,
}) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const user = useSelector(getUser);

  const Task = props => {
    const delay = moment().diff(props?.end_date, 'days');
    const [open, setOpen] = useState(false);
    const [isTaskCompleted, setIsTaskCompleted] = useState(props?.isCompleted);
    const [priority, setPriority] = useState(props?.Priority);
    const [status, setStatus] = useState(props?.Status);
    const [duration, setDuration] = useState(props?.TaskTimers?.[0]?.duration);
    const interval = useRef(null);
    // team
    const [openn, setOpenn] = useState(false);
    const handleOpenn = () => setOpenn(true);
    const handleClosen = () => setOpenn(false);
    // team end
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
      //getting timer
      let Timer = props?.TaskTimers?.[0];
      //if no timer fount
      if (!Timer) return false;
      //if timer found then calculate duration
      let duration = Timer.status
        ? moment(new Date()).diff(Timer.updatedAt, 'seconds') + Timer.duration
        : Timer.duration;
      //setting duration
      setDuration(duration);
    }, []);

    useNonInitialEffect(() => {
      //getting timer
      let Timer = props?.TaskTimers?.[0];
      if (Timer) {
        if (!interval.current)
          interval.current = setInterval(() => {
            setDuration(old => old + 1);
          }, 1000);
      } else clearTimeout(interval.current);
    }, [duration]);

    const getTaskProgress = () => {
      let totalIssues = props?.TaskIssues.length;
      let totalSubTasks = props?.SubTasks.length;

      let totalCompletedSubTasks = props?.SubTasks.filter(
        task => task.completed_on,
      ).length;
      let totalCompletedIssues = props?.TaskIssues.filter(
        issue => issue.completed_on,
      ).length;

      return totalIssues +
        totalSubTasks +
        totalCompletedSubTasks +
        totalCompletedIssues >
        0
        ? Math.round(
            ((totalCompletedSubTasks + totalCompletedIssues) /
              (totalIssues + totalSubTasks)) *
              100 *
              100,
          ) / 100
        : 0;
    };
    const ProjectListDropDown = () => {
      const [anchorEl, setAnchorEl] = useState(null);
      const open = Boolean(anchorEl);
      const handleClick = event => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

      const navigate = useNavigate();
      const options = [
        {
          link: props?.Project
            ? `/Management/update_task/${props.id}`
            : `/Management/update_other_task/${props.id}`,
          name: 'Edit',
        },
        {
          onClick: handleOpen,
          name: 'delete',
          disabled: user.id !== props.createdBy,
        },
        {onClick: handleOpenn, name: 'Team Members'},
        {onClick: '', name: 'Send for approval'},
      ];
      const ITEM_HEIGHT = 30;
      // teme
      const TransitionsModal = ({assignee}) => {
        const User = props => {
          return (
            <div className="col-12">
              <div className="user_card_div_inner">
                <Avatar
                  alt={props?.User?.name}
                  src={props?.User?.avatar}
                  sx={{width: 15, height: 15}}
                />
                <p className=" ms-2">{props?.User?.name}</p>
                <h6>{props?.User?.Designation?.name}</h6>
              </div>
            </div>
          );
        };
        const style = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 4,
        };
        return (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openn}
            onClose={handleClosen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}>
            <Fade in={openn}>
              <Box className="team_list_box" sx={style}>
                <div className="user_card_div_title">
                  <h3>Team Members</h3>
                </div>
                <div className="user_card_div">
                  <div className="row g-2 ">
                    {assignee.map((val, i) => (
                      <User key={i} {...val} />
                    ))}
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        );
      };
      return (
        <>
          <TransitionsModal assignee={props?.TaskMembers} />
          <div className="chat_option">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              className=" m-0 p-0">
              <BiDotsHorizontalRounded />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '15ch',
                },
              }}>
              {options.map((option, i) => (
                <div key={i} onClick={() => navigate(option.link)}>
                  <MenuItem disabled={option?.disabled} onClick={handleClose}>
                    <div
                      onClick={option.onClick}
                      className="chat_option_name_div">
                      {option.name}
                    </div>
                  </MenuItem>
                </div>
              ))}
            </Menu>
          </div>
        </>
      );
    };
    const DeleteModal = () => {
      const handleDeleteTask = (id, idx) => {
        Block.circle('.delete_modal');
        Axios.delete('/maxproject/task/internal_task/' + id)
          .then(({data}) => {
            handleClose();
            setTasks(old => {
              let arr = old.slice();
              arr.splice(idx, 1);
              return arr;
            });
            handleClose();
            Block.remove('.delete_modal');
            Notify.success(data.message);
          })
          .catch(err => {
            //removing loader
            Block.remove('.delete_modal');
            //checking for any error message
            if (err?.response?.data?.message)
              Notify.failure(err?.response?.data?.message);
          });
      };
      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
      };
      return (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={open}>
            <Box sx={style}>
              <div className="delete_modal">
                <ul>
                  <li>
                    <BsFillInfoCircleFill />
                  </li>
                  <li>
                    <h3>Delete "{props?.name}"?</h3>
                    <p>
                      "{props?.name}" will be deleted permanently and cannot be
                      recovered.
                    </p>
                  </li>
                </ul>
                <div className="delete_modal_btn_div">
                  <ul>
                    <li className="btn_one">
                      <OutlineBtn onClick={handleClose} name="Cancel" />
                    </li>
                    <li className="btn_two">
                      <FullBtn
                        onClick={() =>
                          handleDeleteTask(props.id, props.idx, handleClose)
                        }
                        name="Delete"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      );
    };
    const ProjectName = props => {
      const Link = () => {
        return (
          <div className="Participants_main_div_tr">
            <Tooltip title={props?.ProjectName} placement="bottom-start" arrow>
              <NavLink className="link_a" to={props?.link}>
                {/* {props.ProjectName} */}
                {props?.ProjectName.length > 25
                  ? props?.ProjectName?.substr(0, 25) + '...'
                  : props?.ProjectName}
              </NavLink>
            </Tooltip>
          </div>
        );
      };
      const Text = () => {
        return (
          <div className="Participants_main_div_tr">{props.ProjectName}</div>
        );
      };
      return props.link ? <Link /> : <Text />;
    };
    const PriorityDropdown = () => {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
      const handleClick = event => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
      const handlePriorityChange = priority => {
        Block.circle('.dashbaord_white_card');
        Axios.put(`maxproject/task/${props.id}/priority`, {
          priority_id: priority.id,
        })
          .then(({data}) => {
            Block.remove('.dashbaord_white_card');
            setPriority(priority);
            setAnchorEl(null);
          })
          .catch(err => {
            Block.remove('.dashbaord_white_card');
          });
      };
      return (
        <React.Fragment>
          <Box>
            <Tooltip arrow>
              <p
                onClick={handleClick}
                className="Priority_dropdown"
                style={{backgroundColor: priority?.bg_color}}>
                <span style={{color: `${priority?.text_color}`}}>
                  {priority?.name}
                  <AiFillCaretDown />
                </span>
              </p>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
            <Divider />
            {taskPriorityFilters?.map((p, i) => (
              <MenuItem key={i} onClick={() => handlePriorityChange(p)}>
                <p className="Priority_dropdown_item">
                  <BsFillCircleFill style={{color: p.bg_color}} />
                  {p.name}
                </p>
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      );
    };
    const StatusDropdown = () => {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
      const handleClick = event => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
      const handleStatusChange = (status, force = false) => {
        Block.circle('.dashbaord_white_card');
        Axios.put(
          `maxproject/task/${props.id}/status${force ? '?force=1' : ''}`,
          {
            status_id: status.id,
          },
        )
          .then(({data}) => {
            Block.remove('.dashbaord_white_card');
            if (data.status == 1) {
              setStatus(status);
              setAnchorEl(null);
              setIsTaskCompleted(data?.taskCompleted);
            } else if (data.status == -1)
              Confirm.show(data.message, 'Still Proceed!!', 'Yes', 'No', () =>
                handleStatusChange(status, true),
              );
            else if (data.status == -2) Notify.failure(data.message);
            else if (data.status == -3)
              Report.info('Attention', data.message, 'Okay', {
                backOverlayColor: 'rgb(0 0 0 / 51%)',
              });
          })
          .catch(err => {
            Block.remove('.dashbaord_white_card');
          });
      };
      return (
        <React.Fragment>
          <Box>
            <Tooltip arrow>
              <p
                onClick={handleClick}
                className="Priority_dropdown"
                style={{backgroundColor: 'transparent'}}>
                <span style={{color: status?.text_color}}>
                  {status?.name}
                  <AiFillCaretDown />
                </span>
              </p>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
            <Divider />
            {taskStatusFilters?.map((s, i) => (
              <MenuItem key={i} onClick={() => handleStatusChange(s)}>
                <p className="Priority_dropdown_item">
                  <BsFillCircleFill style={{color: s.bg_color}} />
                  {s.name}
                </p>
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      );
    };
    const TaskTitle = () => (
      <div className="Participants_main_div_tr link_a">
        {/* {(page - 1) * 10 + props.idx + 1} - */}
        <Tooltip title="Approval task" placement="top-start" arrow>
          <img className="tasks_icon me-2" src={AprIcon} alt="icon" />
        </Tooltip>
        <Tooltip title={props.name} placement="bottom-start" arrow>
          {props?.name.length > 25
            ? props?.name.substr(0, 25) + '...'
            : props?.name}
        </Tooltip>
      </div>
    );
    return (
      <>
        <DeleteModal />
        <tr
          className="progress_all_tabel_tr_mor"
          style={props?.isDeleted ? {backgroundColor: 'lightgray'} : {}}>
          <td>
            <div className="Participants_main_div_tr">
              {(page - 1) * 10 + props.idx + 1} -
            </div>
          </td>
          <td
            onClick={() =>
              dispatch(setGlobal({taskId: props.id, showTask: true}))
            }>
            {status.isCompleted ? (
              <s>
                <TaskTitle />
              </s>
            ) : (
              <TaskTitle />
            )}
          </td>
          <td className="project_list_name">
            <PriorityDropdown />
          </td>
          <td className="project_list_name">
            <StatusDropdown />
          </td>
          <td>
            {isProjectTasks ? (
              props?.ProjectService?.Service?.name
            ) : (
              <ProjectName
                link={'/Management/project_details/' + props?.Project?.id}
                ProjectName={props?.Project?.name}
              />
            )}
          </td>
          <td>
            <div className="Participants_main_div_tr">
              {status.isCompleted ? 100 : getTaskProgress()}%
            </div>
          </td>
          <td className="Participants_main_div">
            <div className="Participants_main_div_inner">
              <UserParticipants2 data={props?.TaskMembers} />
            </div>
          </td>
          <td>
            <div className="Participants_main_div_tr">{props?.end_date}</div>
          </td>
          <td>
            {isTaskCompleted ? (
              <div>--</div>
            ) : (
              <div style={{color: 'red'}}>
                {delay > 0 ? `${delay} days` : `- -`}
              </div>
            )}
          </td>
          <td>
            <div className="mt-1">
              <div className="progress_all_option_icon">
                <ProjectListDropDown />
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  };
  const TasksTable = () => {
    const NoDataView = () => {
      return (
        <div className="row">
          <div className="col-12">
            <div
              style={{
                minHeight: '30rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '2rem',
              }}>
              <Img
                backgroundImage={NoResultsSVG}
                style={{
                  height: 250,
                  width: 250,
                }}
                className="project_summary_card_icon"
              />
              <span className="mb-3 w-50">
                Sorry, We couldn't find any details that match your filter
                criteria. Please consider clearing your filter or refining your
                criteria for better results.
              </span>
            </div>
          </div>
        </div>
      );
    };
    if (!tasks.length) return <NoDataView />;
    return (
      <>
        <div>
          <table className="table table-hover text-capitalize progress_all_tabel_tabel">
            <thead>
              <tr className="progress_all_tabel_tr">
                <th scope="col">Sr no</th>
                <th scope="col">Task Title</th>
                <th scope="col">Priority</th>
                <th scope="col">Status</th>
                <th scope="col">
                  {isProjectTasks ? 'Service Name' : 'Project Name'}
                </th>
                <th scope="col">Progress</th>
                <th scope="col">Assignee</th>
                <th scope="col">Due Date</th>
                <th scope="col">Delay</th>
                <th scope="col">action</th>
              </tr>
            </thead>
            <tbody>
              {tasks?.slice((page - 1) * 10, 10 * page)?.map((val, i) => (
                <Task idx={i} key={i} {...val} />
              ))}
            </tbody>
          </table>
        </div>
        {tasks?.length > 10 ? (
          <div className="projec_tabelt_list_pagination_div">
            <Stack spacing={2}>
              <Pagination
                onChange={handleTaskPagination}
                count={Math.ceil(tasks?.length / 10)}
              />
            </Stack>
          </div>
        ) : null}
      </>
    );
  };
  const DeletedTasksTable = () => {
    const [show, setShow] = useState(false);
    if (!deletedTasks.length) return null;
    return (
      <>
        <h6 onClick={() => setShow(!show)}>Deleted Tasks</h6>
        {show ? (
          <>
            <div>
              <table className="table table-hover text-capitalize progress_all_tabel_tabel">
                <thead>
                  <tr className="progress_all_tabel_tr">
                    <th scope="col">Task Title</th>
                    <th scope="col">Priority</th>
                    <th scope="col">Status</th>
                    <th scope="col">Project Name</th>
                    <th scope="col">Progress</th>
                    <th scope="col">Assignee</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Delay</th>
                    <th scope="col">action</th>
                  </tr>
                </thead>
                <tbody>
                  {deletedTasks
                    ?.slice((page - 1) * 10, 10 * page)
                    ?.map((val, i) => (
                      <Task isDeleted={true} idx={i} key={i} {...val} />
                    ))}
                </tbody>
              </table>
            </div>
            {deletedTasks?.length > 10 ? (
              <div className="projec_tabelt_list_pagination_div">
                <Stack spacing={2}>
                  <Pagination
                    onChange={handleTaskPagination}
                    count={Math.ceil(deletedTasks?.length / 10)}
                  />
                </Stack>
              </div>
            ) : null}
          </>
        ) : null}
      </>
    );
  };
  const handleTaskPagination = (e, page) => {
    setPage(page);
  };
  return (
    <>
      <div className="dashbaord_white_card">{TasksTable()}</div>
      <div
        style={{marginTop: '1rem', cursor: 'pointer'}}
        className="dashbaord_white_card">
        {DeletedTasksTable()}
      </div>
    </>
  );
};
// TasksListView end
// ClassicView
export const ClassicView = ({
  tasks = [],
  setTasks = () => {},
  taskStatusFilters = [],
  taskPriorityFilters = [],
}) => {
  const dispatch = useDispatch();
  const ClassicTask = props => {
    const services = props?.ProjectServices.filter(
      service => service?.ProjectMilestones?.length,
    );
    if (!services.length) return null;
    const Tasks = ({tasksData}) => {
      const [page, setPage] = useState(1);
      const [tasks, setTasks] = useState(tasksData || []);
      const [showDelete, setShowDelete] = useState(false);
      const [priority, setPriority] = useState(props?.Priority);
      const [status, setStatus] = useState(props?.Status);
      // team
      const [openn, setOpenn] = useState(false);
      const handleOpenn = () => setOpenn(true);
      const handleClosen = () => setOpenn(false);
      // team
      // Delete Modal
      const handleOpen = () => setShowDelete(true);
      const handleClose = () => setShowDelete(false);
      const handleTaskPagination = (e, page) => {
        setPage(page);
      };
      // Delete Modal
      const DeleteModal = () => {
        const style = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        };
        return (
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showDelete}
            onClose={handleClose}
            closeAfterTransition>
            <Fade in={showDelete}>
              <Box sx={style}>
                <div className="delete_modal">
                  <ul>
                    <li>
                      <BsFillInfoCircleFill />
                    </li>
                    <li>
                      <h3>Delete {props.title}?</h3>
                      <p>
                        {props.name} will be deleted permanently and cannot be
                        recovered.
                      </p>
                    </li>
                  </ul>
                  <div className="delete_modal_btn_div">
                    <ul>
                      <li className="btn_one">
                        <OutlineBtn onClick={handleClose} name="Cancel" />
                      </li>
                      <li className="btn_two">
                        <FullBtn name="Delete" />
                      </li>
                    </ul>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        );
      };
      const ProjectListDropDown = ({members, task_id, idx}) => {
        const navigate = useNavigate();

        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);

        const handleDeleteTask = () => {
          const processDeleteTask = () => {
            Block.circle('.delete_modal');
            Axios.delete('/maxproject/task/internal_task/' + task_id)
              .then(({data}) => {
                handleClose();
                setTasks(old => {
                  let arr = old.slice();
                  arr.splice(idx, 1);
                  return arr;
                });
                handleClose();
                Block.remove('.delete_modal');
                Notify.success(data.message);
              })
              .catch(err => {
                //removing loader
                Block.remove('.delete_modal');
                //checking for any error message
                if (err?.response?.data?.message)
                  Notify.failure(err?.response?.data?.message);
              });
          };
          Confirm.show(
            'Delete Task',
            "This action can't be undone",
            'Yes',
            'No',
            processDeleteTask,
          );
        };
        const handleClick = event => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        const options = [
          {link: '/Management/update_task/' + task_id, name: 'Edit'},
          {onClick: handleDeleteTask, name: 'delete'},
          {onClick: handleOpenn, name: 'Team Members'},
        ];
        const ITEM_HEIGHT = 30;
        // teme
        const TransitionsModal = () => {
          const Member = props => {
            return (
              <div className="col-12">
                <div className="user_card_div_inner">
                  <Avatar
                    alt={props?.User?.name}
                    src={props?.User?.avatar}
                    sx={{width: 15, height: 15}}
                  />
                  <p className=" ms-2">{props?.User?.name}</p>
                  <h6>{props?.User?.Designation?.name}</h6>
                </div>
              </div>
            );
          };
          const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 4,
          };
          return (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openn}
              onClose={handleClosen}
              closeAfterTransition>
              <Fade in={openn}>
                <Box className="team_list_box" sx={style}>
                  <div className="user_card_div_title">
                    <h3>Team Members</h3>
                  </div>
                  <div className="user_card_div">
                    <div className="row g-2 ">
                      {members.map((val, i) => (
                        <Member key={i} {...val} />
                      ))}
                    </div>
                  </div>
                </Box>
              </Fade>
            </Modal>
          );
        };
        return (
          <>
            <TransitionsModal />
            <div className="chat_option">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                className=" m-0 p-0">
                <BiDotsHorizontalRounded />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '15ch',
                  },
                }}>
                {options.map(option => (
                  <div key={option.name} onClick={() => navigate(option.link)}>
                    <MenuItem onClick={handleClose}>
                      <div
                        onClick={option.onClick}
                        className="chat_option_name_div">
                        {option.name}
                      </div>
                    </MenuItem>
                  </div>
                ))}
              </Menu>
            </div>
          </>
        );
      };
      const Task = ({task, i}) => {
        const delay = moment().diff(task?.end_date, 'days');
        const [priority, setPriority] = useState(task?.Priority);
        const [status, setStatus] = useState(task?.Status);
        const [isTaskCompleted, setIsTaskCompleted] = useState(
          task?.isCompleted,
        );
        const getTaskProgress = () => {
          let totalIssues = task?.TaskIssues.length;
          let totalSubTasks = task?.SubTasks.length;

          let totalCompletedSubTasks = task?.SubTasks.filter(
            task => task.completed_on,
          ).length;
          let totalCompletedIssues = task?.TaskIssues.filter(
            issue => issue.completed_on,
          ).length;

          return totalIssues +
            totalSubTasks +
            totalCompletedSubTasks +
            totalCompletedIssues >
            0
            ? Math.round(
                ((totalCompletedSubTasks + totalCompletedIssues) /
                  (totalIssues + totalSubTasks)) *
                  100 *
                  100,
              ) / 100
            : 0;
        };

        const PriorityDropdown = () => {
          const [anchorEl, setAnchorEl] = React.useState(null);
          const open = Boolean(anchorEl);
          const handleClick = event => {
            setAnchorEl(event.currentTarget);
          };
          const handleClose = () => {
            setAnchorEl(null);
          };
          const handlePriorityChange = priority => {
            Block.circle('.dashbaord_white_card');
            Axios.put(`maxproject/task/${task.id}/priority`, {
              priority_id: priority.id,
            })
              .then(({data}) => {
                Block.remove('.dashbaord_white_card');
                setPriority(priority);
                setAnchorEl(null);
              })
              .catch(err => {
                Block.remove('.dashbaord_white_card');
              });
          };
          return (
            <React.Fragment>
              <Box>
                <Tooltip arrow>
                  <p
                    onClick={handleClick}
                    className="Priority_dropdown"
                    style={{backgroundColor: priority?.bg_color}}>
                    <span style={{color: `${priority?.text_color}`}}>
                      {priority?.name}
                      <AiFillCaretDown />
                    </span>
                  </p>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
                <Divider />
                {taskPriorityFilters?.map((p, i) => (
                  <MenuItem key={i} onClick={() => handlePriorityChange(p)}>
                    <p className="Priority_dropdown_item">
                      <BsFillCircleFill style={{color: p.bg_color}} />
                      {p.name}
                    </p>
                  </MenuItem>
                ))}
              </Menu>
            </React.Fragment>
          );
        };
        const StatusDropdown = () => {
          const [anchorEl, setAnchorEl] = React.useState(null);
          const open = Boolean(anchorEl);
          const handleClick = event => {
            setAnchorEl(event.currentTarget);
          };
          const handleClose = () => {
            setAnchorEl(null);
          };

          const handleStatusChange = (status, force = false) => {
            Block.circle('.dashbaord_white_card');
            Axios.put(
              `maxproject/task/${task.id}/status${force ? '?force=1' : ''}`,
              {
                status_id: status.id,
              },
            )
              .then(({data}) => {
                Block.remove('.dashbaord_white_card');
                if (data.status == 1) {
                  setStatus(status);
                  setAnchorEl(null);
                  setIsTaskCompleted(data?.taskCompleted);
                } else if (data.status == -1)
                  Confirm.show(
                    data.message,
                    'Still Proceed!!',
                    'Yes',
                    'No',
                    () => handleStatusChange(status, true),
                  );
                else if (data.status == -2) Notify.failure(data.message);
              })
              .catch(err => {
                Block.remove('.dashbaord_white_card');
              });
          };
          return (
            <React.Fragment>
              <Box>
                <Tooltip arrow>
                  <p
                    onClick={handleClick}
                    className="Priority_dropdown"
                    style={{backgroundColor: 'transparent'}}>
                    <span style={{color: status?.text_color}}>
                      {status?.name}
                      <AiFillCaretDown />
                    </span>
                  </p>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
                <Divider />
                {taskStatusFilters?.map((s, i) => (
                  <MenuItem key={i} onClick={() => handleStatusChange(s)}>
                    <p className="Priority_dropdown_item">
                      <BsFillCircleFill style={{color: s.bg_color}} />
                      {s.name}
                    </p>
                  </MenuItem>
                ))}
              </Menu>
            </React.Fragment>
          );
        };
        const TaskTitle = () => (
          <div className="Participants_main_div_tr link_a">
            {(page - 1) * 10 + i + 1} -{' '}
            {task?.name.length > 50
              ? task?.name.substr(0, 50) + '...'
              : task?.name}
          </div>
        );
        return (
          <tr key={i} className="progress_all_tabel_tr_mor">
            <td
              onClick={() =>
                dispatch(setGlobal({taskId: props.id, showTask: true}))
              }
              className="project_list_name ">
              <Tooltip title={task?.name} placement="bottom-start" arrow>
                <div className="link_a">
                  {status.isCompleted || getTaskProgress() == 100 ? (
                    <s>
                      <TaskTitle />
                    </s>
                  ) : (
                    <TaskTitle />
                  )}
                </div>
              </Tooltip>
            </td>
            <td>
              <PriorityDropdown />
            </td>
            <td>
              <StatusDropdown />
            </td>
            <td className="progress_all_tabel_td">
              <div className="progress_all_tabel">
                <div className="row align-items-center">
                  <div className="col-2">
                    <span>
                      {status?.isCompleted ? 100 : getTaskProgress()}%
                    </span>
                  </div>
                  <div className="col-10">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${
                            status?.isCompleted ? 100 : getTaskProgress()
                          }%`,
                        }}
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div
                className="Participants_main_div_inner"
                style={{marginTop: '-3px'}}>
                <UserParticipants2 data={task?.TaskMembers} />
              </div>
            </td>
            <td>{moment(task?.end_date).calendar()}</td>
            <td style={{color: 'red'}}>
              {delay && !isTaskCompleted > 0 ? `${delay} days` : `- -`}
            </td>
            <td>
              <div className="progress_all_option_icon">
                <ProjectListDropDown
                  idx={i}
                  task_id={task?.id}
                  members={task?.TaskMembers}
                />
              </div>
            </td>
          </tr>
        );
      };

      return (
        <div>
          <div className="dashbaord_white_card pt-3 px-2 ">
            <div>
              <table className="table table-hover text-capitalize progress_all_tabel_tabel">
                <thead>
                  <tr className="progress_all_tabel_tr">
                    <th scope="col">Task Name</th>
                    <th scope="col">Priority</th>
                    <th scope="col">Status</th>
                    <th scope="col">Progress</th>
                    <th scope="col">Assignee</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Delay</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <DeleteModal />
                  {tasks?.slice((page - 1) * 10, 10 * page)?.map((task, i) => (
                    <Task key={i} task={task} i={i} />
                  ))}
                </tbody>
              </table>
            </div>
            {tasks?.length > 10 ? (
              <div className="projec_tabelt_list_pagination_div">
                <div className="projec_tabelt_list_pagination_div">
                  <Stack spacing={2}>
                    <Pagination
                      onChange={handleTaskPagination}
                      count={Math.ceil(tasks?.length / 10)}
                    />
                  </Stack>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    };
    const MileStone = milestone => {
      return (
        <Accordion.Item eventKey={Math.random()}>
          <Accordion.Header className="classic_view_tabel_heder">
            <span>{milestone?.title}</span>
          </Accordion.Header>
          <Accordion.Body className="classic_view_tabel_body">
            <Tasks tasksData={milestone?.Tasks} />
          </Accordion.Body>
        </Accordion.Item>
      );
    };
    const Service = ({serviceData}) => {
      return (
        <Accordion.Item eventKey={Math.random()}>
          <Accordion.Header className="classic_view_inner_header">
            <span>{serviceData?.Service?.name}</span>
          </Accordion.Header>
          <Accordion.Body className="classic_view_inner_body">
            <Accordion alwaysOpen>
              {serviceData?.ProjectMilestones?.map((milestone, w) =>
                milestone?.Tasks?.length ? (
                  <MileStone key={w} {...milestone} />
                ) : null,
              )}
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>
      );
    };

    return (
      <Accordion.Item className=" mb-2" eventKey={Math.random()}>
        <Accordion.Header>{props?.name}</Accordion.Header>
        <Accordion.Body className="classic_view_outer_body pt-0">
          <Accordion alwaysOpen>
            {props?.ProjectServices?.map((data, e) =>
              data?.ProjectMilestones?.length ? (
                <Service key={e} serviceData={data} />
              ) : null,
            )}
          </Accordion>
        </Accordion.Body>
      </Accordion.Item>
    );
  };
  return (
    <div className="classic_view_outer">
      <Accordion alwaysOpen>
        {tasks.map((val, i) => (
          <ClassicTask key={i} {...val} />
        ))}
      </Accordion>
    </div>
  );
};
// ClassicView end
// Kanban
export const Kanban = ({kanbanTasks, setKanbanTasks, taskPriorityFilters}) => {
  const dispatch = useDispatch();
  const TasksStatusCard = ({title, tasks}) => {
    const Task = props => {
      const [priority, setPriority] = useState(props?.Priority);
      const PriorityDropdown = () => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = event => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        const handlePriorityChange = priority => {
          Block.circle('.dashbaord_white_card');
          Axios.put(`maxproject/task/${props.id}/priority`, {
            priority_id: priority.id,
          })
            .then(({data}) => {
              Block.remove('.dashbaord_white_card');
              setPriority(priority);
              setAnchorEl(null);
            })
            .catch(err => {
              Block.remove('.dashbaord_white_card');
            });
        };
        return (
          <React.Fragment>
            <Box>
              <Tooltip arrow>
                <p
                  onClick={handleClick}
                  className="Priority_dropdown"
                  style={{backgroundColor: priority?.bg_color}}>
                  <span style={{color: `${priority?.text_color}`}}>
                    {priority?.name}
                    <AiFillCaretDown />
                  </span>
                </p>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{horizontal: 'right', vertical: 'top'}}
              anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
              <Divider />
              {taskPriorityFilters?.map((p, i) => (
                <MenuItem key={i} onClick={() => handlePriorityChange(p)}>
                  <p className="Priority_dropdown_item">
                    <BsFillCircleFill style={{color: p.bg_color}} />
                    {p.name}
                  </p>
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        );
      };
      return (
        <div className="col-12">
          <div className="open_tasks_card">
            <Tooltip title={props.name} placement="bottom-start" arrow>
              <h3
                onClick={() =>
                  dispatch(setGlobal({taskId: props.id, showTask: true}))
                }
                className="link_a">
                {/* {props?.name} */}
                {props?.name.length > 25
                  ? props?.name.substr(0, 25) + '...'
                  : props?.name}
              </h3>
            </Tooltip>
            <div className="open_tasks_img_div">
              <UserParticipants2 data={props?.TaskMembers} />
            </div>
            <div className="project_list_priority_div">
              <PriorityDropdown />
            </div>
          </div>
        </div>
      );
    };

    return (
      <>
        <div className="inner_taska_div_main">
          <div className="inner_taska_title_div_main">
            <h3>{title}</h3>
            <div className="inner_taska_title_btn_div_main">
              <FullBtnWithIcon
                link="/Management/create_task"
                name="Add Task"
                icon={<AiOutlinePlus />}
              />
            </div>

            <div className="row g-3 mt-2 kanban_tasks_card">
              {tasks?.map((val, i) => (
                <Task key={i} {...val} />
              ))}
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3 ">
      {kanbanTasks?.map((status, i) => (
        <div
          key={i}
          className="col-xxl- col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
          <TasksStatusCard title={status?.name} tasks={status?.Tasks} />
        </div>
      ))}
    </div>
  );
};
// Kanban end
export const UserParticipants = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ParticipantsModal = props => {
    const ParticipantsApp = props => {
      return (
        <div className="col-12">
          <div className="user_card_div_inner">
            <Avatar
              alt={props?.User?.name}
              src={props?.User?.avatar}
              sx={{width: 15, height: 15}}
            />
            <p>{props?.User?.name}</p>
            <h6>{props?.User?.Designation?.name}</h6>
          </div>
        </div>
      );
    };
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#ddc8c8',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
        <div className="user_card_div_title">
          <h3>Participants</h3>
        </div>
        <div className="user_card_div">
          <div className="row g-2 ">
            {props.data.map((val, i) => {
              return <ParticipantsApp key={i} {...val} />;
            })}
          </div>
        </div>
      </Menu>
    );
  };
  return (
    <>
      <ParticipantsModal data={props.data} />
      <div className="Participants_icon_div">
        <AvatarGroup onClick={handleClick} max={5}>
          {props.data.map((val, i) => (
            <Avatar key={i} alt={val?.User?.name} src={val?.User?.avatar} />
          ))}
        </AvatarGroup>
        <AiFillCaretDown onClick={handleClick} className="Participants_icon" />
      </div>
    </>
  );
};
export const UserParticipants2 = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ParticipantsModal = props => {
    const ParticipantsApp = props => {
      return (
        <div className="col-12">
          <div className="user_card_div_inner">
            <Avatar
              alt={props?.User?.name}
              src={props?.User?.avatar}
              sx={{width: 15, height: 15}}
            />
            <div className="user_card_div_inner_timer">
              <p>{props?.User?.name}</p>
              <h5>{props?.User?.Designation?.name}</h5>
            </div>
            <div className="user_card_div_inner_timer_timer_div">
              {props?.TaskTimer?.isCompleted ? (
                <>
                  <p style={{color: 'green'}}>Completed</p>
                  <div>
                    <span
                      style={{
                        color: props?.TaskTimer?.status ? 'blue' : 'green',
                      }}>
                      {calDuration(props?.TaskTimer)}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {props?.TaskTimer ? (
                    <p style={{color: '#008B0E'}}>
                      {props?.TaskTimer?.status ? 'Working' : 'Paused'}
                    </p>
                  ) : (
                    <p style={{color: 'red'}}>Not started Yet</p>
                  )}
                  {props?.TaskTimer ? (
                    <div>
                      {props?.TaskTimer?.status ? (
                        <AccessTimeIcon
                          fontSize="medium"
                          style={{color: 'black'}}
                        />
                      ) : (
                        <SnoozeIcon
                          color="black"
                          fontSize="medium"
                          style={{color: 'black'}}
                        />
                      )}
                      <span
                        style={{
                          color: props?.TaskTimer?.status ? 'blue' : 'green',
                        }}>
                        {calDuration(props?.TaskTimer)}
                      </span>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      );
    };
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#ddc8c8',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
        <div className="user_card_div_title">
          <h3>Participants</h3>
        </div>
        <div className="user_card_div">
          <div className="row g-2 ">
            {props.data.map((val, i) => {
              return <ParticipantsApp key={i} {...val} />;
            })}
          </div>
        </div>
      </Menu>
    );
  };
  return (
    <>
      <ParticipantsModal data={props.data} />
      <div className="Participants_icon_div">
        <AvatarGroup onClick={handleClick} max={5}>
          {props.data.map((val, i) => (
            <Avatar key={i} alt={val?.User?.name} src={val?.User?.avatar} />
          ))}
        </AvatarGroup>
        <AiFillCaretDown onClick={handleClick} className="Participants_icon" />
      </div>
    </>
  );
};
