import {useState} from 'react';
import {Routes, Route} from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import Container from '../components/Container/Container';
import TopNavbar from '../components/TopNavbar/TopNavbar';
import {Clients} from '../components/pages/Clients/Clients';
import {Settings} from '../components/pages/Settings/Settings';
import {ClientsProfile} from '../components/pages/Clients/ClientProfile';
import {ClientsSmsForm} from '../components/pages/Clients/ClientSMSForm';

import NavContext from '../Context/NavContext';
const Index = () => {
  const [nav, setNav] = useState(true);
  const value = {nav, setNav};
  return (
    <div className="App">
      <NavContext.Provider value={value}>
        <Navbar />
        <Container
          stickyNav={<TopNavbar />}
          content={
            <Routes>
              <Route path="/clients" element={<Clients />} />
              <Route
                path="/clients/edit/:client_id"
                element={<ClientsProfile />}
              />
              <Route path="/clients/add" element={<ClientsProfile />} />
              <Route
                path="/client_sms/:client_id"
                element={<ClientsSmsForm />}
              />
              <Route path="/settings" element={<Settings />} />
              <Route path="*" element={<main>NOT FOUND</main>} />
            </Routes>
          }
        />
      </NavContext.Provider>
    </div>
  );
};
export default Index;
