import {ENDPOINT} from './index';

export const toHoursAndMinutes = totalMinutes => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  if (totalMinutes < 60) return `${minutes}m`;
  return `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`;
};

export const getIconUrl = file => `${ENDPOINT}icons/${file}`;
export const getRandomFromArray = array =>
  array[Math.floor(Math.random() * array.length)];
