import TaskDetail from '../TaskDetail';
import styles from './Container.module.scss';
import {Box, Drawer} from '@mui/material';
import {getGlobal, setGlobal} from '../../../../redux/slices/globalSlice';
import {useDispatch, useSelector} from 'react-redux';

const Container = ({stickyNav, content}) => {
  const {showTask} = useSelector(getGlobal);
  const dispatch = useDispatch();
  return (
    <div className={styles.container}>
      <Drawer
        anchor="right"
        open={showTask}
        onClose={() => dispatch(setGlobal({showTask: false, taskId: null}))}>
        <Box role="presentation">
          <TaskDetail />
        </Box>
      </Drawer>
      {stickyNav}
      {content}
    </div>
  );
};

export default Container;
